import axios from "axios";
const getCheckpoints = async (event) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/event/${event}/checkpoints`,
			{},
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default getCheckpoints;
