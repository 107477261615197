import React, { useState, useEffect, useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";

const styles = {
	box: {
		position: "absolute",
		width: "234px",
		height: "130px",
		overflow: "hidden",
		bottom: { xs: 20, md: "none" },
		top: { xs: "none", md: 20 },
		left: 16,
		zIndex: 999,
		color: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		bgcolor: "secondary.main",
		borderRadius: "10px",
	},
	closeButton: {
		position: "absolute",
		top: "14px",
		right: "2px",
		transform: "translateY(-50%)",
		color: "lightgray",
		p: 0,
		zIndex: 1000,
	},
	image: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover",
		cursor: "pointer",
	},
	prevButton: {
		position: "absolute",
		top: "50%",
		left: "0px",
		transform: "translateY(-50%)",
		color: "lightgray",
	},
	nextButton: {
		position: "absolute",
		top: "50%",
		right: "0px",
		transform: "translateY(-50%)",
		color: "lightgray",
	},
};

export default function ImageCarousel({ event }) {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isVisible, setIsVisible] = useState(true);

	const handleNext = useCallback(() => {
		if (event.sponsors && event.sponsors.length > 0) {
			setCurrentIndex(
				(prevIndex) => (prevIndex + 1) % event.sponsors.length,
			);
		}
	}, [event.sponsors]);

	const handlePrev = useCallback(() => {
		if (event.sponsors && event.sponsors.length > 0) {
			setCurrentIndex(
				(prevIndex) =>
					(prevIndex - 1 + event.sponsors.length) %
					event.sponsors.length,
			);
		}
	}, [event.sponsors]);

	useEffect(() => {
		if (event.sponsors && event.sponsors.length > 0) {
			const interval = setInterval(() => {
				handleNext();
			}, 10000);

			return () => clearInterval(interval);
		}
	}, [handleNext, handlePrev]);

	if (!event || !event.sponsors || !isVisible || event.sponsors.length === 0)
		return null;

	return (
		<Box
			sx={styles.box}
			role="region">
			<IconButton
				onClick={() => setIsVisible(false)}
				sx={styles.closeButton}>
				<Close />
			</IconButton>
			{event.sponsors.map((sponsor, index) => (
				<Box
					key={index}
					component="img"
					src={sponsor.imgUrl}
					alt={sponsor.name}
					sx={{
						...styles.image,
						left: `${index === currentIndex ? "0%" : "-100%"}`,
					}}
					onClick={() => window.open(sponsor.webUrl, "_blank")}
					aria-hidden={index !== currentIndex}
				/>
			))}
			{event && event.sponsors && event.sponsors.length > 1 && (
				<>
					<IconButton
						onClick={handlePrev}
						sx={styles.prevButton}>
						<ArrowBackIos />
					</IconButton>
					<IconButton
						onClick={handleNext}
						sx={styles.nextButton}>
						<ArrowForwardIos />
					</IconButton>
				</>
			)}
		</Box>
	);
}
