import React, { useMemo } from "react";
import { checkpointIcon } from "../../inne/markery";
import { Marker, Tooltip } from "react-leaflet";

function Checkpoints({ checkpoints, onClick }) {
	const memoizedCheckpoints = useMemo(() => {
		return checkpoints.map((checkpoint, index) => {
			return (
				!!checkpoint && (
					<Marker
						eventHandlers={{
							click: () => onClick(checkpoint.id),
						}}
						position={[checkpoint.lat, checkpoint.lon]}
						key={`checkpoint-${index}`}
						icon={checkpointIcon(checkpoint.orderPoint, 10)}>
						<Tooltip sticky>{checkpoint.name}</Tooltip>
					</Marker>
				)
			);
		});
	}, [checkpoints, onClick]);

	return <>{memoizedCheckpoints}</>;
}

Checkpoints.displayName = "Checkpoints";

export default Checkpoints;
