import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Toolbar,
	Button,
	IconButton,
	Typography,
	Menu,
	MenuItem,
	useMediaQuery,
	useTheme,
	Stack,
	Box,
	Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import TikTokSVG from "../inne/TikTokSVG";

const socialLinks = [
	{
		href: "https://www.instagram.com/velostacja/",
		Icon: InstagramIcon,
		ariaLabel: "Instagram",
	},
	{
		href: "https://www.facebook.com/VeloStacjaPL/",
		Icon: FacebookIcon,
		ariaLabel: "Facebook",
	},
	{
		href: "https://www.youtube.com/@velostacja",
		Icon: YouTubeIcon,
		ariaLabel: "YouTube",
	},
	{
		href: "https://www.tiktok.com/@velostacja.pl",
		Icon: TikTokSVG,
		ariaLabel: "TikTok",
	},
];

const styles = {
	appBar: (scrolled) => ({
		boxShadow: "none",
		backgroundColor: scrolled ? "secondary.main" : "transparent",
		color: scrolled ? "white" : "black",
		transition: "background-color 0.3s ease",
	}),
	typography: {
		flexGrow: 1,
		cursor: "pointer",
	},
	menuPaper: {
		maxWidth: "calc(100vw - 48px)",
		overflow: "hidden",
	},
	stack: {
		width: "100%",
	},
	footerBox: {
		width: "100%",
		color: "white",
		backgroundColor: "secondary.main",
	},
	footerTypography: {
		fontFamily: "Arial",
		fontWeight: 400,
	},
	link: {
		color: "primary.main",
	},
	socialIcons: {
		color: "white",
	},
};

const SiteWrapper = ({ children }) => {
	const navigate = useNavigate();
	const [scrolled, setScrolled] = useState(false);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleScroll = useCallback(() => {
		setScrolled(window.scrollY > 0);
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [handleScroll]);

	const handleClick = useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const handleClose = useCallback(() => {
		setAnchorEl(null);
	}, []);

	return (
		<>
			<AppBar
				position="fixed"
				sx={styles.appBar(scrolled)}>
				<Toolbar>
					<Typography
						variant="h6"
						onClick={() => navigate("/")}
						sx={styles.typography}>
						VeloStacja
					</Typography>
					{isSmallScreen ? (
						<>
							<IconButton
								size="large"
								color="inherit"
								onClick={handleClick}
								aria-label="Open menu">
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								keepMounted
								open={open}
								onClose={handleClose}
								sx={{
									"& .MuiPaper-root": styles.menuPaper,
								}}>
								<MenuItem
									onClick={handleClose}
									component={RouterLink}
									to="/">
									Strona główna
								</MenuItem>
								<MenuItem
									onClick={handleClose}
									component={RouterLink}
									to="/wydarzenia">
									Wydarzenia
								</MenuItem>
								<MenuItem
									onClick={handleClose}
									component={RouterLink}
									to="/kontakt">
									Kontakt
								</MenuItem>
							</Menu>
						</>
					) : (
						<nav>
							<Button
								color="inherit"
								component={RouterLink}
								to="/">
								Strona główna
							</Button>
							<Button
								component={RouterLink}
								to="/wydarzenia"
								color="inherit">
								Wydarzenia
							</Button>
							<Button
								component={RouterLink}
								to="/kontakt"
								color="inherit">
								Kontakt
							</Button>
						</nav>
					)}
				</Toolbar>
			</AppBar>

			<Stack
				alignItems="center"
				justifyContent="center"
				sx={styles.stack}>
				{children}
				<Box
					textAlign="center"
					mt={4}
					py={2}
					sx={styles.footerBox}>
					<Typography sx={styles.footerTypography}>
						VeloStacja Piotr Groszkowski
					</Typography>
					<Typography
						variant="body1"
						sx={{ ...styles.footerTypography, mt: 2 }}>
						E-mail:{" "}
						<Link
							href="mailto:velostacja@velostacja.pl"
							sx={styles.link}>
							velostacja@velostacja.pl
						</Link>
					</Typography>
					<Typography
						variant="body1"
						sx={{ ...styles.footerTypography, mt: "4px" }}>
						Telefon:{" "}
						<Link
							href="tel:+48726321112"
							sx={styles.link}>
							+48 726 321 112
						</Link>
					</Typography>
					<Typography
						variant="body1"
						sx={{ ...styles.footerTypography, mt: 2 }}>
						Obserwuj nas
					</Typography>
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						my={1}>
						{socialLinks.map(({ href, Icon, ariaLabel }) => (
							<IconButton
								component={Link}
								href={href}
								target="_blank"
								rel="noopener"
								key={href}
								aria-label={ariaLabel}>
								<Icon sx={styles.socialIcons} />
							</IconButton>
						))}
					</Box>
				</Box>
			</Stack>
		</>
	);
};

export default SiteWrapper;
