import React from "react";
import { Paper, Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResizableBoxComponent from "../../inne/ResizableBoxComponent";

const styles = {
	headerSpacer: { width: "40px" },
	closeIconStyles: {
		mx: "8px",
		cursor: "pointer",
	},
	closeIconContainerStyles: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paperStyles: {
		width: "100%",
		height: "100%",
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		paddingTop: "12px",
	},
	modalStyles: {
		justifyContent: { xs: "center", md: "flex-start" },
		alignItems: "flex-end",
		pointerEvents: "none",
		display: "flex",
		zIndex: 9999,
		width: "100%",
		height: "100%",
	},
	title: { fontSize: "18px" },
	contentBoxStyles: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-start",
		alignItems: "center",
		flexGrow: 1,
		overflow: "auto",
	},
	content: {
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
};

const ElevationModal = ({ open, onClose, children }) => {
	return (
		<Modal
			open={open}
			onClose={onClose}
			sx={styles.modalStyles}
			BackdropProps={{
				sx: {
					backgroundColor: "transparent",
					pointerEvents: "none",
				},
			}}>
			<div>
				<ResizableBoxComponent>
					<Paper sx={styles.paperStyles}>
						<Box sx={styles.closeIconContainerStyles}>
							<Box sx={styles.headerSpacer} />
							<Typography sx={styles.title}>
								Profil Wysokościowy
							</Typography>
							<CloseIcon
								onClick={onClose}
								sx={styles.closeIconStyles}
							/>
						</Box>
						<Box sx={styles.contentBoxStyles}>
							<Box sx={styles.content}>{children}</Box>
						</Box>
					</Paper>
				</ResizableBoxComponent>
			</div>
		</Modal>
	);
};

export default ElevationModal;
