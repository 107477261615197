import React from "react";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import useWindowWidth from "./useWindowWidth";

const ResizableBoxComponent = ({ children }) => {
	const windowWidth = useWindowWidth();

	const getMargin = (width) => {
		if (width < 900) {
			return "8px 0 20px";
		} else {
			return "0 12px 20px";
		}
	};

	const getWidth = (width) => {
		if (width < 900) {
			return window.innerWidth - 16;
		} else {
			return window.innerWidth - 100;
		}
	};

	const styles = {
		padding: "4px",
		margin: getMargin(windowWidth),
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		pointerEvents: "auto",
	};

	return (
		<ResizableBox
			width={getWidth(windowWidth)}
			height={210}
			minConstraints={[getWidth(windowWidth), 200]}
			maxConstraints={[getWidth(windowWidth), window.innerHeight * 0.8]}
			resizeHandles={["n"]}
			style={styles}
			role="group">
			<>
				<div
					id="resizable-box-label"
					style={{ display: "none" }}>
					Resizable Content Box
				</div>
				<div
					id="resizable-box-description"
					style={{ display: "none" }}>
					You can resize this box by dragging the handle at the top.
				</div>
				<>{children}</>
			</>
		</ResizableBox>
	);
};

export default ResizableBoxComponent;
