import L from "leaflet";
import iconUrl from "../media/rec.png";
import ghostIcon from "../media/ghost.png";

const iconTemplates = {
	InActivePlayerIconHtml: (text) => `
		<div style="
			display: flex; 
			justify-content: center; 
			align-items: center;
			vertical-align: middle;
			flex-direction: column; 
			position: relative;
			width: 26px; 
			height: 26px;
			bottom: 8px;
			right: 8px;
			color: white; 
			border-radius: 50%; 
			text-align: center; 
			background-color: #595959;
		">
			${text}
		</div>`,

	MicsMarkerIconHtml: (svgPath) => `
		<div style="
			position: relative; 
			right: 10px;
			bottom: 10px;
			width: 30px; 
			height: 30px; 
			transform: rotate(45deg); 
			background-color: yellow; 
			display: flex; 
			border: solid 2px black;
			align-items: center; 
			box-sizing: border-box;
			color: #000000;
			justify-content: center;
		">
				<img 
					src="${svgPath}" 
					alt="Marker" 
					width="20" 
					height="20" 
					alt=" "
					style="transform: rotate(-45deg);"
				>
		</div>`,

	playerIconDirectionalHtml: (type, text, angle) => {
		var color;

		if (type === "female") {
			color = "#BE25B9";
		} else if (type === "male") {
			color = "#1976D2";
		} else if (type === "x") {
			color = "purple";
		} else if (type === "DNF" || type === "DSQ") {
			color = "red";
		}

		return `
		<div style="
			position: relative;
			width: 26px; 
			height: 26px; 
			right: 8px;
			bottom: 8px;
			display: flex; 
			justify-content: center; 
			align-items: center;
		">
			<div style="
				position: absolute;
				width: 36px; 
				height: 40px;
				background-color: black; 
				transform: rotate(${angle}deg); 
				transform-origin: center;
				clip-path: polygon(20% 50%, 80% 50%, 50% 0);
				z-index: 0;
			"></div>
			<div style="
				display: flex; 
				justify-content: center; 
				align-items: center;
				vertical-align: middle;
				flex-direction: column; 
				position: relative;
				width: 26px; 
				height: 26px; 
				color: white; 
				border-radius: 50%; 
				text-align: center; 
				${color === "red" ? "font-size: 10px; color: black;" : ""}
				background-color: ${color}; 
				z-index: 1;
			">
				${text}
			</div>
		</div>`;
	},

	playerGroupIconHtml: (cluster) => {
		const colors = new Set();

		const colorRegex = /#([0-9a-fA-F]{6})/;
		for (const marker of cluster.getAllChildMarkers()) {
			const html = marker.options.icon.options.html;
			const match = colorRegex.exec(html);
			if (match) {
				colors.add(match[1]);
			}
		}

		const hasPink = colors.has("BE25B9");
		const hasBlue = colors.has("1976D2");

		let topColor = "#595959";
		let bottomColor = "#595959";

		if (hasPink && hasBlue) {
			topColor = "#BE25B9";
			bottomColor = "#1976D2";
		} else if (hasPink) {
			topColor = bottomColor = "#BE25B9";
		} else if (hasBlue) {
			topColor = bottomColor = "#1976D2";
		}

		return `
			<span style="
				background: #ffffff;
				border-left: 7px solid ${topColor};
				border-top: 7px solid ${topColor};
				border-right: 7px solid ${bottomColor};
				border-bottom: 7px solid ${bottomColor};
				border-radius: 50%;
				color: #000000;
				height: 21px;
				vertical-align: center;
				display: flex; 
				justify-content: center; 
				align-items: center;
				text-align: center;
				position: relative;
				right: 15px;
				bottom: 15px;
				width: 21px;
			">
				${cluster.getChildCount()}
			</span>`;
	},

	currentPositionIconHtml: (iconUrl) => `
		<img src="${iconUrl}" alt="Twoja Lokalizacja" width="26" height="26" style="border-radius: 50%;">`,

	startFinishIconHtml: (text, color) => `
		<div style="
			position: relative; 
			right: 16px;
			bottom: 16px;
			width: 32px; 
			height: 32px; 
			transform: rotate(45deg); 
			background-color: ${color}; 
			display: flex; 
			align-items: center; 
			justify-content: center;
		">
			<div style="transform: rotate(-45deg);">
				${text}
			</div>
		</div>`,

	startFinishGroupIconHtml: () => `
		<div style="
			position: relative; 
			right: 17px;
			bottom: 17px;
			width: 34px;
			height: 34px; 
			background: linear-gradient(135deg, green 50%, red 50%); 
			display: flex; 
			align-items: center; 
			justify-content: center;
			flex-direction: column;
			transform: rotate(45deg);
		">
			<div style="transform: rotate(-45deg);">
				<div style="font-size: 10px;">Start</div>
				<div style="font-size: 10px;">Meta</div>
			</div>
		</div>`,

	checkpointIconHtml: (text) => `
		<div style="
			position: relative; 
			right: 10px;
			bottom: 10px;
			width: 30px; 
			height: 30px; 
			border: solid 3px red;
			transform: rotate(45deg); 
			background-color: yellow; 
			box-sizing: border-box;
			color: black;
			display: flex; 
			align-items: center; 
			justify-content: center;
		">
			<div style="
				font-weight: bold;
				width: 24px;
				height: 24px;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				transform: rotate(-45deg);">
				${text}
			</div>
		</div>`,

	technicalIconHtml: (svgPath) => `
		<div style="
		display: flex; 
		justify-content: center; 
		align-items: center;
		vertical-align: middle;
		right: 9px;
		bottom: 9px;
		flex-direction: column; 
		position: relative;
		width: 30px; 
		height: 30px;
		color: white; 
		border-radius: 50%; 
		text-align: center; 
		color: black;
		background-color: yellow;">
		<img 
			src="${svgPath}" 
			alt="Punkt Techniczny" 
			width="22" 
			height="22">
		</div>`,

	GhostIconHtml: () => `
		<div style="
		display: flex; 
		justify-content: center; 
		align-items: center;
		vertical-align: middle;
		right: 9px;
		bottom: 9px;
		flex-direction: column; 
		position: relative;
		width: 30px; 
		height: 30px;
		color: white; 
		border-radius: 50%; 
		text-align: center; 
		color: black;
		background-color: #B19CD9;">
		<img 
			src="${ghostIcon}" 
			alt="Duch" 
			width="30px" 
			height="30px">
		</div>`,
};

const InActivePlayerIcon = (text) =>
	L.divIcon({
		html: iconTemplates.InActivePlayerIconHtml(text),
	});

const GhostIcon = () =>
	L.divIcon({
		html: iconTemplates.GhostIconHtml(),
	});

const TechnicalIcon = (svgPath) =>
	L.divIcon({
		html: iconTemplates.technicalIconHtml(svgPath),
	});

const MicsMarkerIcon = (svgPath) =>
	L.divIcon({
		html: iconTemplates.MicsMarkerIconHtml(svgPath),
	});

const playerIconDirectional = (female, text, angle) =>
	L.divIcon({
		html: iconTemplates.playerIconDirectionalHtml(female, text, angle),
	});

const playerGroupIcon = (cluster) =>
	L.divIcon({
		html: iconTemplates.playerGroupIconHtml(cluster),
	});

const currentPositionIcon = new L.Icon({
	iconUrl: iconUrl,
	iconSize: [26, 26],
	iconAnchor: [13, 13],
});

const startFinishIcon = (text, color) =>
	L.divIcon({
		html: iconTemplates.startFinishIconHtml(text, color),
	});

const startFinishGroupIcon = () =>
	L.divIcon({
		html: iconTemplates.startFinishGroupIconHtml(),
	});

const checkpointIcon = (text, angle) =>
	L.divIcon({
		html: iconTemplates.checkpointIconHtml(text, angle),
	});

export {
	InActivePlayerIcon,
	playerGroupIcon,
	currentPositionIcon,
	startFinishIcon,
	playerIconDirectional,
	startFinishGroupIcon,
	MicsMarkerIcon,
	checkpointIcon,
	TechnicalIcon,
	GhostIcon,
	iconTemplates,
};
