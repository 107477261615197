import React, { useMemo } from "react";
import { TechnicalIcon } from "../../inne/markery";
import { Tooltip, Marker } from "react-leaflet";
import { Stack, Typography } from "@mui/material";

function Technicals({ technicals }) {
	const memoizedTechnicals = useMemo(() => {
		return technicals.map((technical, index) => {
			return (
				!!technical && (
					<Marker
						key={`Technical-${index}`}
						position={[
							technical.deviceStatus.lat,
							technical.deviceStatus.lon,
						]}
						icon={TechnicalIcon(technical.imgUrl)}>
						<Tooltip>
							<Stack>
								<Typography>{technical.name}</Typography>
								{technical.desc}
							</Stack>
						</Tooltip>
					</Marker>
				)
			);
		});
	}, [technicals]);

	return <>{memoizedTechnicals}</>;
}

Technicals.displayName = "Technicals";

export default Technicals;
