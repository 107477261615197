import axios from "axios";

const deleteCheckpoint = async (checkpointId) => {
	try {
		const response = await axios.delete(
			`${process.env.REACT_APP_API_URL}/admin/checkpoint/remove/${checkpointId}`,
			{},
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default deleteCheckpoint;
