import React from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import { startFinishIcon, startFinishGroupIcon } from "../../inne/markery";
import { Marker } from "react-leaflet";

function StartFinishMarkers({ track, handleTopBarOpen }) {
	return (
		<>
			{track && track.length >= 1 && (
				<MarkerClusterGroup
					iconCreateFunction={startFinishGroupIcon}
					maxClusterRadius={66}
					animate={false}
					removeOutsideVisibleBounds
					onClick={(e) => handleTopBarOpen("statusy")}
					showCoverageOnHover={false}>
					<Marker
						position={track[0].point}
						key={"start"}
						icon={startFinishIcon("start", "green")}
						eventHandlers={{
							click: (e) => handleTopBarOpen("players"),
						}}
					/>
					<Marker
						position={track.at(-1).point}
						key={"finish"}
						icon={startFinishIcon("meta", "red")}
						eventHandlers={{
							click: (e) => handleTopBarOpen("statusy"),
						}}
					/>
				</MarkerClusterGroup>
			)}
		</>
	);
}
StartFinishMarkers.displayName = "StartFinishMarkers";

export default StartFinishMarkers;
