import axios from "axios";

const postAddCheckpoint = async (checkpointData) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/admin/checkpoint/add`, checkpointData
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default postAddCheckpoint;