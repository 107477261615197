import React, { Fragment, useState, useEffect } from "react";
import {
	Button,
	Card,
	CardContent,
	CardMedia,
	Stack,
	IconButton,
	Box,
	Typography,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Notifications from "../inne/Notifications";
import SiteWrapper from "./SiteWrapper";
import TikTokSVG from "../inne/TikTokSVG";
import { Link as RouterLink } from "react-router-dom";

const iconStyle = {
	color: "white",
	width: 21,
	height: 21,
	padding: "5px",
	borderRadius: "50%",
	backgroundColor: "secondary.main",
};

const socialLinks = [
	{
		href: "https://www.instagram.com/velostacja/",
		Icon: InstagramIcon,
		name: "Instagram",
	},
	{
		href: "https://www.facebook.com/VeloStacjaPL/",
		Icon: FacebookIcon,
		name: "Facebook",
	},
	{
		href: "https://www.youtube.com/@velostacja",
		Icon: YouTubeIcon,
		name: "YouTube",
	},
	{
		href: "https://www.tiktok.com/@velostacja.pl",
		Icon: TikTokSVG,
		name: "TikTok",
	},
];

const styles = {
	heroBox: {
		textAlign: "center",
		backgroundColor: "secondary.main",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo_main.png)`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		height: "520px",
		width: "100%",
	},
	heroTitle: {
		fontWeight: "700",
		fontFamily: "Arial",
		color: "white",
		fontSize: {
			xs: "40px",
			sm: "40px",
			md: "85px",
		},
	},
	heroSubtitle: {
		fontWeight: "400",
		fontFamily: "Arial",
		color: "white",
		fontSize: {
			xs: "18px",
			sm: "18px",
			md: "33px",
		},
	},
	socialIconsContainer: {
		display: "flex",
		justifyContent: "center",
		gap: "1px",
	},
	eventsButton: {
		width: "115px",
		px: 3,
		color: "white",
		borderColor: "white",
		"&:hover": {
			borderColor: "white",
			backgroundColor: "rgba(255, 255, 255, 0.1)",
		},
	},
	aboutSection: {
		m: 2,
		maxWidth: { xs: "300px", sm: "300px", md: "none" },
	},
	aboutImage: {
		maxWidth: "300px",
		width: "auto",
		height: "auto",
	},
	aboutText: {
		textAlign: "justify",
		fontFamily: "Arial",
		fontWeight: 400,
		fontSize: 15,
		maxWidth: "650px",
		verticalAlign: "middle",
		p: 1,
	},
	sectionTitleBox: {
		width: "100%",
		height: "120px",
		bgcolor: "secondary.main",
		my: 2,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	sectionTitle: {
		fontWeight: 700,
		fontSize: "34px",
		color: "white",
	},
	cardContainer: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		maxWidth: 300,
		m: 1,
	},
	cardImage: {
		height: 300,
		width: 300,
		objectFit: "cover",
	},
	cardContent: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	cardTitle: {
		fontWeight: 700,
		fontSize: "1.25rem",
	},
	cardText: {
		color: "text.secondary",
	},
};

const StartPage = () => {
	const [content, setContent] = useState(null);

	useEffect(() => {
		fetch(process.env.PUBLIC_URL + '/text/stronaGlowna.json')
		  .then(response => {
			if (!response.ok) {
			  throw new Error('Network response error');
			}
			return response.json();
		  })
		  .then(data => {
			setContent(data);
		  })
	  }, []);

	return (
		<SiteWrapper>
			<Box sx={styles.heroBox}>
				<Stack
					spacing={1}
					alignItems="center"
					justifyContent="center">
					<Typography
						variant="h1"
						sx={styles.heroTitle}>
						VeloStacja
					</Typography>
					<Typography
						variant="h2"
						sx={styles.heroSubtitle}>
						- z nami zobaczysz więcej -
					</Typography>
					<Box sx={styles.socialIconsContainer}>
						{socialLinks.map(({ href, Icon, name }) => (
							<IconButton
								component="a"
								href={href}
								target="_blank"
								aria-label={name}
								key={href}>
								<Icon sx={iconStyle} />
							</IconButton>
						))}
					</Box>
					<Button
						variant="outlined"
						size="large"
						component={RouterLink} 
						to="/wydarzenia"
						sx={styles.eventsButton}>
						Wydarzenia
					</Button>
				</Stack>
			</Box>
			<Notifications />
			{content &&
				<Stack
					direction={{ sm: "column", md: "row" }}
					spacing={2}
					sx={styles.aboutSection}>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							m: 2,
						}}>
						<img
							component="img"
							src={`${process.env.PUBLIC_URL}${content.about.image}`}
							alt="Tracking System"
							width="auto"
							height="auto"
							style={styles.aboutImage}
						/>
					</Box>
					<Typography
						variant="body1"
						sx={styles.aboutText}>
							{content.about.text}
					</Typography>
				</Stack>
			}
			{content && Object.keys(content.data).map((key, containerIndex) => (
				<Fragment key={`cardContainer-${containerIndex}`}>
					<Box sx={styles.sectionTitleBox}>
						<Typography
							variant="h3"
							sx={styles.sectionTitle}>
							{key}
						</Typography>
					</Box>
					<Stack direction={{ sm: "column", md: "row" }}>
						{content.data[key].map((item, cardIndex) => (
							<Card
								key={`card-${cardIndex}`}
								sx={styles.cardContainer}>
								<CardMedia
									component="img"
									image={process.env.PUBLIC_URL + item.image}
									alt={item.title}
									sx={styles.cardImage}
								/>
								<CardContent sx={styles.cardContent}>
									<Typography
										align="center"
										sx={styles.cardTitle}
										gutterBottom>
										{item.title}
									</Typography>
									<Typography
										variant="body2"
										sx={styles.cardText}
										align="center">
										{item.text}
									</Typography>
								</CardContent>
							</Card>
						))}
					</Stack>
				</Fragment>
			))}
		</SiteWrapper>
	);
};

export default StartPage;
