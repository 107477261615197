import { useState, useEffect} from "react";
import {
	Box,
	Grid,
	TextField,
	Stack,
	Checkbox,
	FormControlLabel,
	Button,
	Typography,
	Input,
	IconButton,
	Paper,
	List,
	ListItem
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";
import getEvent from "../requests/getEvent";
import useHandleRequest from "../requests/useHandleRequest";
import postEventModify from "../requests/postEventModify";

const styles = {
	form: {
		display: "flex",
		flexDirection: "column",
		gap: 2,
	},
	checkboxRow: {
		width: "100%",
	},
	fileUploadStack: {
		width: "100%",
	},
	uploadedFileText: {
		mt: 0.5,
		width: "100%",
	},
	hiddenInput: {
		display: "none",
	},
};

const SettingsEventForm = ({ selectedEvent, events }) => {
	const handleRequest = useHandleRequest();
	const [formData, setFormData] = useState({
		slug: "",
		eventName: "",
		eventDescription: "",
		startDateTime: "",
		endDateTime: "",
		imgUrl: "",
		isPublic: false,
		eventImage: { file: null, name: "" },
		courseFiles: [], // Array to store multiple course files
		relatedEvents: [],
		competitorCategory: [],
		sponsors: [], // Array to store multiple sponsor logos
		ghostOption: false,
	});

	const handleChangeCategory = (index, event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => {
		  const newCategory = [...prevFormData.competitorCategory];
		  newCategory[index] = {
			...newCategory[index],
			[name]: value,
		  };
		  return {
			...prevFormData,
			competitorCategory: newCategory,
		  };
		});
	};

	const handleAddCategory = () => {
		setFormData((prevFormData) => ({
		  ...prevFormData,
		  competitorCategory: [...prevFormData.competitorCategory, { id: '', name: '' }],
		}));
	  };
  
	const handleRemoveCategory = (index) => {
		setFormData((prevFormData) => ({
		  ...prevFormData,
		  competitorCategory: prevFormData.competitorCategory.filter((_, catIndex) => catIndex !== index),
		}));
	};

	const handleChangeSponsor = (index, event) => {
		const { name, value } = event.target;
		setFormData((prevFormData) => {
		  const newSponsors = [...prevFormData.sponsors];
		  newSponsors[index] = {
			...newSponsors[index],
			[name]: value,
		  };
		  return {
			...prevFormData,
			sponsors: newSponsors,
		  };
		});
	};

	const handleCheckboxChange = (event, eventItem) => {
		if (event.target.checked) {
		  setFormData((prevFormData) => ({
			...prevFormData,
			relatedEvents: [...prevFormData.relatedEvents, eventItem],
		  }));
		} else {
		  setFormData((prevFormData) => ({
			...prevFormData,
			relatedEvents: prevFormData.relatedEvents.filter(
			  (e) => e.slug !== eventItem.slug
			),
		  }));
		}
	  };

	const handleAddSponsor = () => {
		setFormData((prevFormData) => ({
		  ...prevFormData,
		  sponsors: [...prevFormData.sponsors, { name: '', imgUrl: '', webUrl: '' }],
		}));
	  };
  
	const handleRemoveSponsor = (index) => {
		setFormData((prevFormData) => ({
		  ...prevFormData,
		  sponsors: prevFormData.sponsors.filter((_, sponsorIndex) => sponsorIndex !== index),
		}));
	};

	useEffect(() => {
		const fetchData = async () => {
			const [eventSuccess, eventResponse] = await handleRequest(
				getEvent,
				selectedEvent.slug,
			);
			if (eventSuccess)
				setFormData((prev) => ({
					...prev,
					slug: eventResponse.slug,
					eventName: eventResponse.name,
					eventDescription: eventResponse.desc,
					startDateTime: eventResponse.dateStart,
					endDateTime: eventResponse.dateEnd,
					imgUrl: eventResponse.imgUrl,
					isPublic: false,
					eventImage: { file: null, name: "" },
					courseFiles: [],
					sponsors: eventResponse.sponsors,
					competitorCategory: eventResponse.competitorCategory,
					relatedEvents: eventResponse.relatedEvents,
					ghostOption: false,
				}));
		};
		fetchData();
	}, [selectedEvent.slug]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}));
	};

	const handleUpload = (event) => {
		const { name, files } = event.target;
		const file = files[0];

		if (file) {
			setFormData((prev) => ({
				...prev,
				[name]: [...prev[name], { file, name: file.name }],
			}));
		}
	};

	const handleDeleteFile = (name, index) => {
		setFormData((prev) => ({
			...prev,
			[name]: prev[name].filter((_, i) => i !== index),
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log(formData);

		handleRequest(postEventModify, {
			slug: formData.slug,
			name: formData.eventName,
			dateStart: formData.startDateTime,
			dateEnd: formData.endDateTime,
			desc: formData.eventDescription,
			relatedEvents: formData.relatedEvents,
			sponsors: formData.sponsors,
			competitorCategory: formData.competitorCategory
		})
		.then(response => {
			console.log(response[1]);
		})
		.catch(error => {
			console.error(error);
		})
	};

	return (
		<Box
			component="form"
			onSubmit={handleSubmit}
			sx={styles.form}>
			<TextField
				label="Nazwa wydarzenia"
				name="eventName"
				value={formData.eventName}
				onChange={handleChange}
				required
			/>
			<TextField
				label="Identyfikator zawodów"
				name="slug"
				value={formData.slug}
				onChange={handleChange}
				required
			/>
			<TextField
				label="URL logo"
				name="imgUrl"
				value={formData.imgUrl}
				onChange={handleChange}
			/>
			<TextField
				label="Opis wydarzenia"
				name="eventDescription"
				value={formData.eventDescription}
				onChange={handleChange}
				multiline
				rows={4}
			/>
			<Stack>
				<Typography sx={{m: 1}}>Kategorie</Typography>
				{formData.competitorCategory.map((cat, index) => (
					<Grid container spacing={2} alignItems="center" key={'category_' + index} sx={{ mt:0.1 }}>
						<Grid item xs={0.1}>{index + 1}.</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="ID"
								name="id"
								value={cat.id || ''}
								onChange={ (event) => handleChangeCategory(index, event) }
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="Nazwa"
								name="name"
								value={cat.name || ''}
								onChange={ (event) => handleChangeCategory(index, event) }
							/>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								color="error"
								onClick={ () => handleRemoveCategory(index) }
								aria-label="delete"
							>
							<CloseIcon fontSize="small" />
						</IconButton>
						</Grid>
					</Grid>
					))}
					<Button
						variant="outlined"
						component="span"
						onClick={handleAddCategory}
						style={{ marginTop: '16px' }}
					>
						Dodaj kategorię
					</Button>
			</Stack>

			<TextField
				label="Data i godzina rozpoczęcia"
				name="startDateTime"
				type="datetime-local"
				value={formData.startDateTime}
				onChange={handleChange}
				required
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<TextField
				label="Data i godzina zakończenia"
				name="endDateTime"
				type="datetime-local"
				value={formData.endDateTime}
				onChange={handleChange}
				required
				InputLabelProps={{
					shrink: true,
				}}
			/>
			<Stack>
				<Typography sx={{m: 1}}>Sponsorzy</Typography>
				{formData.sponsors.map((sponsor, index) => (
					<Grid container spacing={2} alignItems="center" key={index} sx={{ mt:0.1 }}>
						<Grid item xs={0.1}>{index + 1}.</Grid>
						<Grid item xs={3}>
							<TextField
								fullWidth
								label="Nazwa"
								name="name"
								value={sponsor.name || ''}
								onChange={ (event) => handleChangeSponsor(index, event) }
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="URL obrazka"
								name="imgUrl"
								value={sponsor.imgUrl || ''}
								onChange={ (event) => handleChangeSponsor(index, event) }
							/>
						</Grid>
						<Grid item xs={2}>
							<TextField
								fullWidth
								label="URL strony"
								name="webUrl"
								value={sponsor.webUrl || ''}
								onChange={ (event) => handleChangeSponsor(index, event) }
							/>
						</Grid>
						<Grid item xs={2}>
							<IconButton
								color="error"
								onClick={ () => handleRemoveSponsor(index) }
								aria-label="delete"
							>
							<CloseIcon fontSize="small" />
						</IconButton>
						</Grid>
					</Grid>
					))}
					<Button
						variant="outlined"
						component="span"
						onClick={handleAddSponsor}
						style={{ marginTop: '16px' }}
					>
						Dodaj sponsora
					</Button>
			</Stack>
			<Stack>
				<Typography>Powiązane wydarzenia</Typography>
				<Paper style={{ padding: '16px' }}>
					<List key={`list-related-events`}>
					{events.map((event, index) => (
						<ListItem key={`listitemevent-${index}`} dense>
						<FormControlLabel
							control={
								<Checkbox
									name={`checkbox-${event.slug}`}
									checked={ formData.relatedEvents.some(x => x.slug === event.slug) }
									onChange={ (e) => handleCheckboxChange(e, event) }
									disabled={ selectedEvent.slug === event.slug }
								/>
							}
							label={`${event.name}`}
							/>
						</ListItem>
					))}
					</List>
				</Paper>
			</Stack>
			<Stack
				direction="row"
				sx={styles.checkboxRow}>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.isPublic}
							onChange={handleChange}
							name="isPublic"
						/>
					}
					label="Dostępne publicznie"
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.ghostOption}
							onChange={handleChange}
							name="ghostOption"
						/>
					}
					label="Dodaj “ducha” do zawodów"
				/>
			</Stack>
			<Stack
				direction="row"
				spacing={2}
				sx={styles.fileUploadStack}>
				<Stack>
					<label htmlFor="eventImage">
						<Input
							id="eventImage"
							type="file"
							name="eventImage"
							accept="image/*"
							onChange={handleUpload}
							sx={styles.hiddenInput}
						/>
						<Button
							variant="outlined"
							component="span"
							startIcon={<UploadIcon />}>
							Wgraj logo/zdjęcie wydarzenia
						</Button>
					</label>
					{formData.eventImage.name && (
						<Typography
							variant="caption"
							color="text.secondary"
							sx={styles.uploadedFileText}>
							Dodano: {formData.eventImage.name}
						</Typography>
					)}
					<Box>
						<small>Min. rozmiar grafiki: 300x200</small>
					</Box>
				</Stack>
				<Stack>
					<label htmlFor="courseFiles">
						<Input
							id="courseFiles"
							type="file"
							name="courseFiles"
							accept=".kml"
							onChange={handleUpload}
							sx={styles.hiddenInput}
						/>
						<Button
							variant="outlined"
							component="span"
							startIcon={<UploadIcon />}>
							Dodawanie trasy (plik .kml)
						</Button>
					</label>
					{formData.courseFiles.map((file, index) => (
						<Stack
							key={index}
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							sx={styles.uploadedFileText}>
							<Typography
								variant="caption"
								color="text.secondary">
								Dodano: {file.name}
							</Typography>
							<Button
								sx={{ minWidth: 0 }}
								size="small"
								onClick={() =>
									handleDeleteFile("courseFiles", index)
								}>
								<CloseIcon fontSize="small" />
							</Button>
						</Stack>
					))}
				</Stack>
			</Stack>
			<Button
				type="submit"
				variant="contained"
				color="primary">
				Submit
			</Button>
		</Box>
	);
};

export default SettingsEventForm;
