import React, { useCallback, useEffect, useRef, useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { useMap, Marker, Popup } from "react-leaflet";
import { currentPositionIcon } from "../../inne/markery";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import LocationDisabledIcon from "@mui/icons-material/LocationDisabled";
import useNotifications from "../../inne/useNotifications";

const fabStyles = {
	position: "fixed",
	bottom: 20,
	right: 16,
	zIndex: 999,
};

const LocateUserFab = () => {
	const [userLocation, setUserLocation] = useState(null);
	const [isViewSet, setIsViewSet] = useState(false);
	const [isLocationError, setIsLocationError] = useState(false);
	const [isRefreshingLocation, setIsRefreshingLocation] = useState(false);

	const map = useMap();
	const locationIntervalRef = useRef(null);
	const addNotification = useNotifications();

	const handleLocateUser = useCallback(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const { latitude, longitude } = position.coords;
					setUserLocation([latitude, longitude]);

					if (!isViewSet) {
						map.setView([latitude, longitude], 20);
						setIsViewSet(true);
					}

					if (!isRefreshingLocation) {
						setIsRefreshingLocation(true);
						locationIntervalRef.current = setInterval(() => {
							navigator.geolocation.getCurrentPosition(
								(position) => {
									const { latitude, longitude } =
										position.coords;
									setUserLocation([latitude, longitude]);
								},
								(error) => {
									setIsLocationError(true);
									addNotification(
										`Błąd lokalizacji`,
										"error",
									);
								},
								{
									enableHighAccuracy: true,
									timeout: 5000,
									maximumAge: 0,
								},
							);
						}, 5000);
					}

					map.setView([latitude, longitude], 20);
				},
				(error) => {
					setIsLocationError(true);
					addNotification(`Błąd lokalizacji`, "error");
				},
				{
					enableHighAccuracy: true,
					timeout: 5000,
					maximumAge: 0,
				},
			);
		} else {
			setIsLocationError(true);
			addNotification(`Błąd lokalizacji`, "error");
		}
	}, [map, isViewSet, isRefreshingLocation]);

	useEffect(() => {
		return () => {
			if (locationIntervalRef.current) {
				clearInterval(locationIntervalRef.current);
			}
		};
	}, []);

	useEffect(() => {
		if (isLocationError && locationIntervalRef.current) {
			clearInterval(locationIntervalRef.current);
			setIsRefreshingLocation(false);
		}
	}, [isLocationError]);

	const getFabIcon = () => {
		if (isLocationError) {
			return <LocationDisabledIcon />;
		}
		return userLocation === null ? (
			<LocationSearchingIcon />
		) : (
			<MyLocationIcon />
		);
	};

	return (
		<>
			<Tooltip title="Moja Lokalizacja">
				<Fab
					size="large"
					color={isLocationError ? "error" : "primary"}
					onClick={handleLocateUser}
					sx={fabStyles}>
					{getFabIcon()}
				</Fab>
			</Tooltip>
			{userLocation && (
				<Marker
					position={userLocation}
					icon={currentPositionIcon}>
					<Popup sticky>Jesteś tutaj!</Popup>
				</Marker>
			)}
		</>
	);
};

export default LocateUserFab;
