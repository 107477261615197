import {
	Box,
	Stack,
	TextField,
	RadioGroup,
	FormControl,
	FormControlLabel,
	Radio,
	Select,
	MenuItem,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import useDebounce from "../../inne/useDebounce";
import getCheckpointStats from "../../requests/getCheckpointStats";
import { useParams } from "react-router-dom";
import useHandleRequest from "../../requests/useHandleRequest";
import dataGridLocaleText from "../../inne/dataGridLocaleText";

const styles = {
	modal: {
		marginTop: "50px",
		marginBottom: "12px",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		zIndex: 9999,
		width: "100%",
		height: "calc(100% - 62px)",
	},
	modalBox: {
		width: "95%",
		height: "100%",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
	},
	paper: {
		width: "100%",
		height: "100%",
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		maxWidth: "850px",
	},
	closeIconBox: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
	},
	closeIcon: {
		margin: "8px",
		cursor: "pointer",
	},
	contentBox: {
		display: "flex",
		flexDirection: "column",
		paddingX: "16px",
		paddingBottom: "10px",
		justifyContent: "flex-start",
		alignItems: "center",
		flexGrow: 1,
		overflow: "auto",
	},
	stack: {
		alignItems: "center",
		direction: "column",
		boxSizing: "border-box",
		width: "100%",
		height: "100%",
	},
	typography: {
		textAlign: "center",
		m: "4px",
	},
	searchTextField: {
		margin: "8px",
		maxWidth: "400px",
	},
	radioGroup: {
		"& .MuiFormControlLabel-label": {
			fontSize: { xs: "13px", sm: "18px" },
		},
	},
	formControl: {
		marginBottom: "6px",
		maxWidth: "400px",
		width: "100%",
	},
	selectMenu: {
		zIndex: 99999999999,
		maxHeight: "300px",
	},
	dataGridContainer: {
		display: "flex",
		flexGrow: 1,
		width: "100%",
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
	},
	dataGrid: {
		flexGrow: 1,
		maxWidth: "650px",
		".MuiDataGrid-cell": {
			fontSize: "17px",
		},
	},
};

function SearchTextField({ setSearchText }) {
	const [text, setText] = useState("");
	const debounced = useDebounce(text, 400);

	useEffect(() => {
		setSearchText(text);
	}, [debounced]);

	return (
		<TextField
			size="small"
			fullWidth
			sx={styles.searchTextField}
			label="szukaj"
			type="text"
			value={text}
			onChange={(e) => {
				setText(e.target.value);
			}}
		/>
	);
}

const ScoreGridFilter = (
	query,
	rows,
	setRows,
	genderFilter,
	categoryFilter,
) => {
	if (
		query.length > 0 ||
		genderFilter !== "ALL" ||
		categoryFilter !== "OPEN"
	) {
		const filtered = rows.map((row) => {
			const genderMatch =
				String(row.female) === genderFilter || genderFilter === "ALL";
			const joinedRow = row.name + row.startNumber;
			const textMatch = joinedRow
				.toUpperCase()
				.replace(/\s/g, "")
				.includes(query.toUpperCase().replace(/\s/g, ""));
			const catMatch = row.categories.includes(categoryFilter);

			return {
				...row,
				visible: genderMatch && textMatch && catMatch,
			};
		});
		setRows(filtered);
	} else {
		setRows(rows.map((row) => ({ ...row, visible: true })));
	}
};

function WynikiCheckpoint({
	wynikiCheckpointState,
	setWynikiCheckpointState,
	event,
}) {
	const [rows, setRows] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [genderFilter, setGenderFilter] = useState("ALL");
	const [categoryFilter, setCategoryFilter] = useState("OPEN");
	const { slug } = useParams();
	const handleRequest = useHandleRequest();

	useEffect(() => {
		ScoreGridFilter(
			searchText,
			rows,
			setRows,
			genderFilter,
			categoryFilter,
		);
	}, [searchText, genderFilter, categoryFilter]);

	useEffect(() => {
		if (wynikiCheckpointState.id === null) return;

		const fetchData = async () => {
			const [success, response] = await handleRequest(
				getCheckpointStats,
				[slug, wynikiCheckpointState.id],
			);
			if (success) {
				setRows(
					response.competitors.map((competitor, index) => ({
						...competitor,
						visible: true,
						id: index.toString(),
						time: competitor.timePoiInMinutes,
						category: competitor.category,
					})),
				);
				setWynikiCheckpointState((prev) => ({
					...prev,
					title: response.name,
				}));
			}
		};
		fetchData();
	}, []);

	const columns = [
		{
			field: "position",
			headerName: "Pos.",
			width: 45,
		},
		{
			field: "startNumber",
			headerName: "#",
			width: 45,
		},
		{ field: "name", headerName: "Imie i Nazwisko", width: 150 },
		{
			field: "time",
			headerName: "Czas",
			width: 100,
			valueFormatter: (minutes) => {
				if (minutes == null) {
					return "";
				}
				const hours = Math.floor(minutes / 60);
				const remainingMinutes = minutes % 60;

				if (hours > 0) {
					return `${hours}h ${remainingMinutes}m`;
				} else {
					return `${remainingMinutes}m`;
				}
			},
		},
	];

	return (
		<Stack
			alignItems="center"
			direction="column"
			boxSizing="border-box"
			width="100%"
			height="100%">
			<SearchTextField setSearchText={setSearchText} />
			<FormControl component="fieldset">
				<RadioGroup
					row
					value={genderFilter}
					onChange={(e) => setGenderFilter(e.target.value)}
					sx={styles.radioGroup}>
					<FormControlLabel
						value="ALL"
						control={<Radio />}
						label="Wszyscy"
					/>
					<FormControlLabel
						value={true}
						control={<Radio />}
						label="Kobiety"
					/>
					<FormControlLabel
						value={false}
						control={<Radio />}
						label="Mężczyźni"
					/>
				</RadioGroup>
			</FormControl>
			<FormControl sx={styles.formControl}>
				<Select
					value={categoryFilter}
					size="small"
					MenuProps={{ sx: styles.selectMenu }}
					onChange={(e) => setCategoryFilter(e.target.value)}>
					{event.competitorCategory &&
						event.competitorCategory.map((cat) => (
							<MenuItem
								key={cat.id}
								value={cat.id}>
								{cat.name}
							</MenuItem>
						))}
				</Select>
			</FormControl>
			<Box sx={styles.dataGridContainer}>
				<DataGrid
					sx={styles.dataGrid}
					rows={useMemo(() => {
						const vis = rows.filter((team) => team.visible);

						const sorted = vis.sort((a, b) => a.time - b.time);
						const numbered = sorted.map((row, index) => ({
							...row,
							position: index + 1,
						}));
						return numbered;
					}, [rows])}
					columns={columns}
					getRowId={(row) => row.id}
					disableColumnMenu
					initialState={{
						sorting: {
							sortModel: [
								{
									field: "time",
									sort: "asc",
								},
							],
						},
					}}
					disableColumnFilter
					disableColumnSelector
					disableDensitySelector
					localeText={dataGridLocaleText}
					disableMultipleRowSelection
					disableColumnSorting
					disableMultipleColumnsFiltering={false}
					autoHeight={false}
				/>
			</Box>
		</Stack>
	);
}

export default WynikiCheckpoint;
