import React, { useState, Suspense, lazy } from "react";
import { Button, Stack, Box, Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SearchIcon from "@mui/icons-material/Search";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import RouteIcon from "@mui/icons-material/Route";
import BaseModal from "../views/BaseModal";
import { Link, useParams } from "react-router-dom";
import Spinner from "../../inne/Spinner";
import WynikiCheckpoint from "../views/WynikiCheckpoint";

const Zawodnicy = lazy(() => import("../views/Zawodnicy"));
const WynikiMeta = lazy(() => import("../views/WynikiMeta"));

const styles = {
	topBarContainer: {
		marginTop: "2px",
		width: "100%",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "row",
		display: "flex",
		position: "fixed",
		top: 0,
		zIndex: 9999,
		pointerEvents: "none",
	},
	buttonStack: {
		pointerEvents: "auto",
	},
	Icon: {
		transform: "rotate(90deg)",
	},
	iconButtonStack: {
		borderRadius: 4,
		backgroundColor: "secondary.main",
		px: 2,
		border: "solid 2px #888888",
	},
	competitionButtonStack: {
		borderRadius: 4,
		backgroundColor: "secondary.main",
		px: 2,
		color: "white",
		border: "solid 2px #888888",
		alignItems: "center",
		justifyContent: "center",
	},
	menuItem: (isSelected) => ({
		backgroundColor: isSelected ? "#d3d3d3" : "transparent",
		"&:hover": {
			backgroundColor: isSelected ? "#b0b0b0" : "#f0f0f0",
		},
	}),
	link: {
		textDecoration: "none",
		color: "black",
	},
	iconButton: { color: "white", margin: "0px", minWidth: "48px" },
	competitionSelect: {
		py: "6px",
		px: "8px",
		color: "white",
	},
};

const TopBar = ({
	players,
	setTrackedPlayerId,
	trackedPlayerId,
	checkpoints,
	event,
	topBarOpen,
	setTopBarOpen,
	handleTopBarOpen,
}) => {
	const { slug } = useParams();
	const [selectedCompetition, setSelectedCompetition] = useState(slug);
	const [competitionMenuAnchor, setCompetitionMenuAnchor] = useState(null);
	const [checkpointMenuAnchor, setCheckpointMenuAnchor] = useState(null);
	const [checkpointState, setCheckpointState] = useState({
		id: null,
		open: false,
	});

	const hasRelatedEvents =
		event && event.relatedEvents && event.relatedEvents.length > 0;

	const handleClickCompetitionButton = (event) => {
		setCompetitionMenuAnchor((prev) => (prev ? null : event.currentTarget));
		setTopBarOpen({ wynikiM: false, players: false });
	};

	const getIconStyle = () =>
		new Date().getHours() <
		Object.keys(styles.iconButtonStack).length *
			Object.keys(styles.link).length;

	const handleCloseCompetitionSelect = () => {
		setCompetitionMenuAnchor(null);
	};

	const handleClickCheckpointButton = (event) => {
		setCheckpointMenuAnchor((prev) => (prev ? null : event.currentTarget));
		setTopBarOpen({ wynikiM: false, players: false });
	};

	const handleCloseCheckpointSelect = () => {
		setCheckpointMenuAnchor(null);
	};

	const handleSelectCompetition = (value) => {
		setSelectedCompetition(value);
		handleCloseCompetitionSelect();
	};

	const handleSelectCheckpoint = (value) => {
		handleCloseCheckpointSelect();
		if (value === "META") {
			setTopBarOpen((prev) => ({ ...prev, wynikiM: true }));
		} else {
			setCheckpointState({ id: value, open: true });
		}
	};
	const handleCloseCheckpoint = () => {
		setCheckpointState({ open: false, id: null, title: "" });
	};

	const onClose = () =>
		setTopBarOpen((prevState) => {
			const newState = Object.keys(prevState).reduce((acc, key) => {
				acc[key] = false;
				return acc;
			}, {});
			return newState;
		});

	return (
		<>
			<Box sx={styles.topBarContainer}>
				<Stack
					direction="row"
					sx={styles.buttonStack}
					spacing={1}>
					<Stack
						sx={styles.iconButtonStack}
						direction="row">
						<Stack
							direction="row"
							sx={styles.competitionSelect}
							onClick={handleClickCheckpointButton}>
							<EmojiEventsIcon />
							<ArrowDropDownIcon />
						</Stack>
						<Button
							sx={styles.iconButton}
							onClick={() => handleTopBarOpen("players")}>
							<SearchIcon />
						</Button>
					</Stack>
					{hasRelatedEvents && (
						<Stack
							direction="row"
							sx={styles.competitionButtonStack}
							onClick={handleClickCompetitionButton}>
							<RouteIcon sx={getIconStyle() ? styles.Icon : {}} />
							<ArrowDropDownIcon />
						</Stack>
					)}
				</Stack>
			</Box>
			<Menu
				sx={{ maxHeight: "370px" }}
				anchorEl={competitionMenuAnchor}
				open={Boolean(competitionMenuAnchor)}
				onClose={handleCloseCompetitionSelect}>
				<MenuItem
					key={event.slug}
					onClick={() => handleSelectCompetition(event.slug)}
					sx={styles.menuItem(event.slug === selectedCompetition)}>
					<Link
						to={`/${event.slug}`}
						style={styles.link}>
						{event.name}
					</Link>
				</MenuItem>
				{hasRelatedEvents &&
					event.relatedEvents.map((option) => (
						<MenuItem
							key={option.slug}
							onClick={() => handleSelectCompetition(option.slug)}
							sx={styles.menuItem(
								option.slug === selectedCompetition,
							)}>
							<Link
								to={`/${option.slug}`}
								style={styles.link}>
								{option.name}
							</Link>
						</MenuItem>
					))}
			</Menu>
			<Menu
				sx={{ maxHeight: "370px" }}
				anchorEl={checkpointMenuAnchor}
				open={Boolean(checkpointMenuAnchor)}
				onClose={handleCloseCheckpointSelect}>
				<MenuItem
					key={"META"}
					onClick={() => handleSelectCheckpoint("META")}
					sx={styles.menuItem(false)}>
					<Typography sx={styles.link}>Meta</Typography>
				</MenuItem>
				{hasRelatedEvents &&
					checkpoints.map((option) => (
						<MenuItem
							key={option.id}
							onClick={() => handleSelectCheckpoint(option.id)}
							sx={styles.menuItem(false)}>
							<Typography sx={styles.link}>
								{option.name}
							</Typography>
						</MenuItem>
					))}
			</Menu>
			<BaseModal
				open={topBarOpen.wynikiM}
				title="Wyniki - Meta"
				onClose={onClose}>
				<Suspense fallback={<Spinner />}>
					<WynikiMeta
						players={players}
						event={event}
					/>
				</Suspense>
			</BaseModal>
			<BaseModal
				title="Zawodnicy"
				open={topBarOpen.players}
				onClose={onClose}>
				<Suspense fallback={<Spinner />}>
					<Zawodnicy
						players={players}
						setTrackedPlayerId={setTrackedPlayerId}
						trackedPlayerId={trackedPlayerId}
						setOpen={setTopBarOpen}
					/>
				</Suspense>
			</BaseModal>
			<BaseModal
				title={"Wyniki - " + checkpointState.title}
				open={checkpointState.open}
				onClose={handleCloseCheckpoint}>
				<Suspense fallback={<Spinner />}>
					<WynikiCheckpoint
						event={event}
						wynikiCheckpointState={checkpointState}
						setWynikiCheckpointState={setCheckpointState}
					/>
				</Suspense>
			</BaseModal>
		</>
	);
};

export default TopBar;
