import React from "react";
import { Paper, Box, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
	headerSpacer: { width: "40px" },
	contentBoxStyles: {
		display: "flex",
		flexDirection: "column",
		paddingX: "12px",
		paddingBottom: "10px",
		justifyContent: "flex-start",
		alignItems: "center",
		flexGrow: 1,
		overflow: "auto",
	},
	closeIconStyles: {
		margin: "8px",
		cursor: "pointer",
	},
	closeIconContainerStyles: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
	},
	paperStyles: {
		width: "calc(100% - 16px)",
		height: "calc(100% - 16px)",
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		maxWidth: 850,
		margin: "px",
	},
	modalStyles: {
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		zIndex: 9999,
	},
	title: { fontSize: "20px" },
};

function BaseModal({ open, onClose, children, title }) {
	return (
		<Modal
			open={open}
			sx={styles.modalStyles}
			onClose={onClose}
			BackdropProps={{
				onClick: onClose,
			}}>
			<Paper sx={styles.paperStyles}>
				<Box sx={styles.closeIconContainerStyles}>
					<Box sx={styles.headerSpacer} />
					<Typography sx={styles.title}>{title}</Typography>
					<CloseIcon
						onClick={onClose}
						sx={styles.closeIconStyles}
					/>
				</Box>
				<Box sx={styles.contentBoxStyles}>{children}</Box>
			</Paper>
		</Modal>
	);
}

export default BaseModal;
