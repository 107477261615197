import React, { useState, useEffect } from "react";
import {
	Box,
	Typography,
	Stack,
	Paper,
	Modal,
	Button,
	FormControlLabel,
	Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { iconTemplates } from "../../inne/markery";
import Qicon from "../../media/question-sign.png";

const styles = {
	modal: {
		marginTop: "50px",
		marginBottom: "12px",
		justifyContent: "center",
		alignItems: "center",
		display: "flex",
		zIndex: 9999,
		width: "100%",
		height: "calc(100% - 62px)",
	},
	paper: {
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		margin: "8px",
		width: "calc(100% - 16px)",
		maxWidth: "300px",
	},
	closeIconBox: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		width: "100%",
	},
	closeIcon: {
		margin: "8px",
		cursor: "pointer",
	},
	checkboxContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	stack: {
		direction: "column",
	},
	saveButtonContainer: {
		padding: 1,
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "center",
		width: "100%",
		boxSizing: "border-box",
	},
	htmlContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		marginLeft: 8,
		width: "80px",
	},
	headerContainer: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		mb: 1,
	},
	headerSpacer: { width: "40px" },
	header: { fontSize: "18px" },
	icon: {
		margin: "8px",
		cursor: "pointer",
	},
	layerContainer: {
		display: "flex",
		justifyContent: "space-between",
	},
};

const layers = [
	{
		label: "Serwis",
		key: "technicals",
		html: iconTemplates.technicalIconHtml(Qicon),
		style: { left: "9px", top: "9px" },
	},
	{
		label: "Inne punkty",
		key: "micsMarkers",
		html: iconTemplates.MicsMarkerIconHtml(Qicon),
		style: { left: "10px", top: "10px" },
	},
	{
		label: "Checkpoint",
		key: "chackpoints",
		html: iconTemplates.checkpointIconHtml("1"),
		style: { left: "9px", top: "9px" },
	},
	{
		label: "Zawodnicy",
		key: "players",
		html: iconTemplates.playerIconDirectionalHtml("female", "AA", 0),
		style: { left: "8px", top: "8px" },
	},
	{
		label: "Trasa",
		key: "route",
		html: `<div style="width: 85px; height: 1px; background-color: #5d5d5d; border: 7px solid #5d5d5d; border-radius: 12px;">`,
		style: {},
	},
	{
		label: "Start/Meta",
		key: "startFinish",
		html: iconTemplates.startFinishGroupIconHtml(),
		style: { left: "17px", top: "17px" },
	},
];

const LayerSelectionModal = ({ showLayer, setShowLayer, isOpen, onClose }) => {
	const [localLayerState, setLocalLayerState] = useState(showLayer);

	useEffect(() => {
		if (isOpen) {
			setLocalLayerState(showLayer);
		}
	}, [isOpen, showLayer]);

	const onSave = () => {
		setShowLayer(localLayerState);
		onClose();
	};

	const handleBackdropClick = (event) => {
		if (event.target === event.currentTarget) {
			onClose();
		}
	};

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			sx={styles.modal}
			BackdropProps={{
				onClick: handleBackdropClick,
			}}>
			<Paper sx={styles.paper}>
				<Box style={styles.headerContainer}>
					<Box style={styles.headerSpacer} />
					<Typography style={styles.header}>
						Widoczne warstwy
					</Typography>
					<CloseIcon
						onClick={onClose}
						style={styles.icon}
					/>
				</Box>
				<Box sx={styles.checkboxContainer}>
					<Stack
						spacing={1.5}
						sx={styles.stack}>
						{layers.map((layer) => (
							<Box
								onClick={() =>
									setLocalLayerState({
										...localLayerState,
										[layer.key]:
											!localLayerState[layer.key],
									})
								}
								key={layer.key}
								sx={styles.layerContainer}>
								<FormControlLabel
									control={
										<Checkbox
											checked={localLayerState[layer.key]}
										/>
									}
									label={layer.label}
								/>
								<Box
									sx={{
										...styles.htmlContainer,
										...layer.style,
									}}
									dangerouslySetInnerHTML={{
										__html: layer.html,
									}}
								/>
							</Box>
						))}
					</Stack>
				</Box>

				<Box sx={styles.saveButtonContainer}>
					<Button onClick={onSave}>Zapisz</Button>
				</Box>
			</Paper>
		</Modal>
	);
};

export default LayerSelectionModal;
