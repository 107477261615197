import React from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
	playerIconDirectional,
	playerGroupIcon,
	InActivePlayerIcon,
} from "../../inne/markery";
import { Tooltip, Marker, useMap } from "react-leaflet";
import { Typography, Stack } from "@mui/material";
import dayjs from "dayjs";
import best from "../../media/best.png";
import second from "../../media/second.png";
import third from "../../media/third.png";

const styles = {
	typographyProps: {
		fontSize: 13,
	},

	boldTypographyProps: {
		fontSize: 13,
		fontWeight: "bold",
	},

	stackProps: {
		width: "100%",
		justifyContent: "space-between",
		direction: "row",
		spacing: 1,
	},
	nameTypography: {
		width: "100%",
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "bold",
		mb: "3px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
};

const formatMinutes = (minutes) => {
	if (minutes == null) {
		return "";
	}
	const hours = Math.floor(minutes / 60);
	const remainingMinutes = minutes % 60;

	if (hours > 0) {
		return `${hours}h ${remainingMinutes}m`;
	} else {
		return `${remainingMinutes}m`;
	}
};

const formatDate = (isoString) => {
	if(!isoString)
		return "";

	return dayjs(isoString).format("YYYY-MM-DD HH:mm");
};

function hasTimePassed(minutes, dateString) {
	return new Date() - new Date(dateString) >= minutes * 60000;
}

const MedalIconComponent = (icon, altText) => (
	<img
		src={icon}
		alt={altText}
		style={{
			marginLeft: "8px",
			width: "auto",
			height: "20px",
		}}
	/>
);

const playerMedals = (pos) => {
	if (pos <= 3) {
		if (pos === 1) {
			return MedalIconComponent(best, "1 msc.");
		} else if (pos === 2) {
			return MedalIconComponent(second, "2 msc.");
		} else {
			return MedalIconComponent(third, "3 msc.");
		}
	}
};

const PlayerMarker = React.memo(({ zawodnik, event, zawLen }) => {
	const map = useMap();
	const centerOnPlayer = (e) => {
		map.setView(e.latlng, map.getZoom());
	};

	const didTimePass = hasTimePassed(
		event.competitorBreakDuration,
		zawodnik.deviceStatus?.lastUpdate,
	);

	const markerIcon = () => {
		if (false) {
			return playerIconDirectional(
				"DNF",
				"DNF",
				zawodnik.deviceStatus.degrees,
			);
		} else if (false) {
			return playerIconDirectional(
				"DSQ",
				"DSQ",
				zawodnik.deviceStatus.degrees,
			);
		} else if (didTimePass) {
			return InActivePlayerIcon(
				zawodnik.name
					.split(" ")
					.map((nazwa) => nazwa[0])
					.join(""),
			);
		}
		return playerIconDirectional(
			zawodnik.female ? "female" : "male",
			zawodnik.name
				.split(" ")
				.map((nazwa) => nazwa[0])
				.join(""),
			zawodnik.deviceStatus?.degrees,
		);
	};

	return (
		zawodnik.deviceStatus && <Marker
			position={[zawodnik.deviceStatus?.lat, zawodnik.deviceStatus?.lon]}
			icon={markerIcon()}
			eventHandlers={{
				click: centerOnPlayer,
			}}>
			<Tooltip
				direction="top"
				offset={[0, -10]}
				opacity={1}
				sticky>
				<Stack
					direction="column"
					sx={{ minWidth: "150px" }}>
					<Typography sx={styles.nameTypography}>
						{event.showStartNumber &&
							"#" + zawodnik.startNumber + " - "}
						{zawodnik.name}
						{false && " (DSQ)"}
						{false && " (DNF)"}
						{playerMedals(zawodnik.stats?.position)}
					</Typography>
					{[
						{
							label: "Team:",
							value: zawodnik.teamName,
							show: !!zawodnik.teamName,
						},
						{
							label: "Nr. grupy:",
							value: zawodnik.groupNumber,
							show: event.showGroupNumber,
						},

						{
							label: "Miejsce:",
							value: zawodnik.stats?.position + "/" + zawLen,
							show: event.showPosition,
						},
						{
							label: "Start:",
							value: formatDate(zawodnik.timeStart),
							show: true,
						},
						{
							label: "ETA:",
							value: zawodnik.stats?.eta,
							show: event.showEta,
						},
						{
							label: "Czas podróży:",
							value: formatMinutes(zawodnik.stats?.travelTime),
							show: event.showTravelTime,
						},
						{
							label: "Prędkość:",
							value: `${zawodnik.deviceStatus?.speed} km/h`,
							show: event.showSpeed,
						},
						{
							label: "Średnia prędkość:",
							value: `${zawodnik.stats?.speedAvg.toFixed(1)} km/h`,
							show: event.showSpeedAvg,
						},
						{
							label: "Od startu:",
							value: zawodnik.stats?.kmTravelled + " km",
							show: true,
						},
						{
							label: "Do nast. PK.:",
							value: zawodnik.stats?.nextPoi + " km",
							show: event.showNextPoi,
						},
						{
							label: "Do mety:",
							value: zawodnik.stats?.kmRemaining + " km",
							show: true,
						},
						{
							label: "Do nast. zawodnika:",
							value: zawodnik.stats?.kmToNextCompetitor + " km",
							show: event.showKmToNextCompetitor,
						},
						{
							label: "Do pop. zawodnika:",
							value: zawodnik.stats?.kmToPreviousCompetitor + " km",
							show: event.showKmToPreviousCompetitor,
						},

						{
							label: "Bateria:",
							value: `${zawodnik.deviceStatus?.battery}%`,
							show: event.showBattery,
						},
						{
							label: "Ostatnio widziany:",
							value: formatDate(zawodnik.deviceStatus?.lastUpdate),
							show: didTimePass,
						},
					]
						.filter((zaw) => zaw.show)
						.map(({ label, value }) => (
							<Stack
								key={label}
								{...styles.stackProps}>
								<Typography {...styles.boldTypographyProps}>
									{label}
								</Typography>
								<Typography {...styles.typographyProps}>
									{value}
								</Typography>
							</Stack>
						))}
				</Stack>
			</Tooltip>
		</Marker>
	);
});

function Players({ players, event }) {
	const zawLen = players.length;

	const memoizedPlayers = () => {
		return players.map((zawodnik, index) => {
			return (
				zawodnik.lastPosition !== null && (
					<PlayerMarker
						key={index}
						zawodnik={zawodnik}
						event={event}
						zawLen={zawLen}
					/>
				)
			);
		});
	};

	return (
		<MarkerClusterGroup
			iconCreateFunction={playerGroupIcon}
			spiderfyOnMaxZoom
			maxClusterRadius={80}
			animate={false}
			removeOutsideVisibleBounds
			showCoverageOnHover={false}
			chunkedLoading
			chunkInterval={300}
			zoomToBoundsOnClick>
			{memoizedPlayers()}
		</MarkerClusterGroup>
	);
}

Players.displayName = "Players";
PlayerMarker.displayName = "PlayerMarker";

export default Players;
