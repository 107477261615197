import axios from "axios";

const postEmailKontakt = async (data) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/api/email/kontakt`, data
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default postEmailKontakt;