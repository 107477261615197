import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
	Box,
	Button,
	Card,
	CardContent,
	CardMedia,
	Grid,
	Stack,
	Typography,
	Tabs,
	Tab,
} from "@mui/material";
import SiteWrapper from "./SiteWrapper";
import getAllEvents from "../requests/getAllEvents";
import useHandleRequest from "../requests/useHandleRequest";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

function categorizeDates(objects) {
	const today = dayjs().startOf('day');
  
	const past = [];
	const current = [];
	const upcoming = [];
  
	objects.forEach((obj) => {
	  const dateStart = dayjs(obj.dateStart).startOf('day');
	  const dateEnd = dayjs(obj.dateEnd).startOf('day');
  
	  if (dateEnd.isBefore(today)) {
		past.push(obj);
	  } else if (dateStart.isSameOrBefore(today) && dateEnd.isSameOrAfter(today)) {
		current.push(obj);
	  } else if (dateStart.isAfter(today)) {
		upcoming.push(obj);
	  }
	});
  
	return { past, current, upcoming };
  }

const formatDate = (isoString) => {
	if(!isoString)
		return '';

	return dayjs(isoString).format('YYYY-MM-DD HH:mm');
};

const styles = {
	headerBox: {
		textAlign: "center",
		backgroundColor: "secondary",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo_main.png)`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		height: "350px",
		width: "100%",
	},
	headerTypography: {
		fontWeight: "700",
		fontFamily: "Arial",
		color: "white",
		fontSize: { xs: "35px", md: "75px" },
	},
	headerButton: {
		marginTop: "20px",
		color: "white",
		borderColor: "white",
		"&:hover": {
			borderColor: "white",
			backgroundColor: "rgba(255, 255, 255, 0.1)",
		},
	},
	tabs: {
		mt: 3,
		minHeight: "300px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	card: {
		maxWidth: 300,
		m: 1,
	},
	cardMedia: {
		height: 300,
		width: 300,
		objectFit: "cover",
	},
	cardContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	cardButton: {
		px: "10px",
		width: "100%",
		minWidth: 0,
	},
};

const NoEventContantPlaceholder = () => (
	<Stack
		spacing={2}
		sx={{ ml: 2 }}>
		<Typography sx={{ textWrap: "wrap" }}>
			Tu może być Twoje wydarzenie lub zawody.{" "}
		</Typography>
		<Button
			component={RouterLink}
			to="/kontakt"
			variant="contained">
			Skontaktuj się
		</Button>
	</Stack>
);

const renderCompetitionCards = (competitionArray, type) => (
	<Grid
		container
		spacing={2}
		justifyContent="center">
		{competitionArray.length > 0 ? (
			competitionArray.map((item, index) => (
				<Grid
					item
					key={index}>
					<Card sx={styles.card}>
						<CardMedia
							component="img"
							image={item.imgUrl}
							alt={item.title}
							sx={styles.cardMedia}
						/>
						<CardContent sx={styles.cardContent}>
							<Typography
								variant="h6"
								align="center"
								fontWeight={700}
								gutterBottom>
								{item.name}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								align="center">
								{item.desc}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								align="center">
								Data Rozpoczęcia: {formatDate(item.dateStart)}
							</Typography>
							<Typography
								variant="body2"
								color="text.secondary"
								align="center">
								Data zakończenia {formatDate(item.dateEnd)}
							</Typography>
							<Stack
								direction="row"
								mt={"16px"}
								width={200}
								spacing={2}>
								<Button
									component={RouterLink}
									to={`/${item.slug}`}
									variant="outlined"
									sx={styles.cardButton}>
									Obserwuj
								</Button>
							</Stack>
						</CardContent>
					</Card>
				</Grid>
			))
		) : (
			<>
				{type === "upcoming" && <NoEventContantPlaceholder />}
				{type === "current" && <NoEventContantPlaceholder />}
				{type === "past" && (
					<Typography>Brak zawodów do wyświetlenia.</Typography>
				)}
			</>
		)}
	</Grid>
);

function Zawody() {
	const [events, setEvents] = useState({
		past: [],
		current: [],
		upcoming: [],
	});
	const [selectedTab, setSelectedTab] = useState(1);
	const handleRequest = useHandleRequest();

	useEffect(() => {
		const fetchData = async () => {
			const [eventsSuccess, eventsResponse] = await handleRequest(
				getAllEvents,
			);
			if (eventsSuccess) setEvents(categorizeDates(eventsResponse));
		};
		fetchData();
	}, []);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<SiteWrapper>
			<Box sx={styles.headerBox}>
				<Stack
					spacing={1}
					alignItems="center"
					justifyContent="center">
					<Typography
						variant="h2"
						sx={styles.headerTypography}>
						Wydarzenia
					</Typography>
					<Button
						component={RouterLink}
						to="/kontakt"
						variant="outlined"
						sx={styles.headerButton}>
						+ Dodaj swoje wydarzenie
					</Button>
				</Stack>
			</Box>
			<Tabs
				value={selectedTab}
				onChange={handleTabChange}
				centered>
				<Tab
					label={`Zakończone`}
					sx={{ px: { xs: "6px", md: 2 } }}
				/>
				<Tab
					label={`W trakcie`}
					sx={{ px: { xs: "6px", md: 2 } }}
				/>
				<Tab
					label={`Wkrótce`}
					sx={{ px: { xs: "6px", md: 2 } }}
				/>
			</Tabs>
			<Box sx={styles.tabs}>
				{selectedTab === 2 &&
					renderCompetitionCards(events.upcoming, "upcoming")}
				{selectedTab === 1 &&
					renderCompetitionCards(events.current, "current")}
				{selectedTab === 0 &&
					renderCompetitionCards(events.past, "past")}
			</Box>
		</SiteWrapper>
	);
}

export default Zawody;
