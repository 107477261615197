import React, { useMemo } from "react";
import { MicsMarkerIcon } from "../../inne/markery";
import { Tooltip, Marker } from "react-leaflet";
import { Typography } from "@mui/material";

function Markers({ markers }) {
	const memoizedMarkers = useMemo(() => {
		return markers.map((marker, index) => {
			return (
				!!marker && (
					<Marker
						key={`Marker-${index}`}
						position={[marker.lat, marker.lon]}
						icon={MicsMarkerIcon(marker.imgUrl)}>
						<Tooltip>
							{" "}
							<Typography>{marker.name}</Typography>
							{marker.desc || ""}
						</Tooltip>
					</Marker>
				)
			);
		});
	}, [markers]);

	return <>{memoizedMarkers}</>;
}

Markers.displayName = "Markers";

export default Markers;
