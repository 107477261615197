import React, {
	useState,
	useEffect,
	useCallback,
	useRef,
	lazy,
	Suspense,
} from "react";
import { MapContainer, Marker } from "react-leaflet";
import getTrack from "../requests/getTrack";
import TopBar from "./inputs/TopBar";
import LocateUserFab from "./inputs/LocateUserFab";
import getListaZawodnikow from "../requests/getListaZawodnikow";
import { useParams } from "react-router-dom";
import getEvent from "../requests/getEvent";
import getMarkers from "../requests/getMarkers";
import getCheckpoints from "../requests/getCheckpoints";
import Players from "./layers/Players";
import Checkpoints from "./layers/Checkpoints";
import Markers from "./layers/Markers";
import Polylines from "./layers/Polylines";
import StartFinishMarkers from "./layers/StartFinishMarkers";
import LayersFab from "./inputs/LayersFab";
import { TileLayer } from "react-leaflet";
import { Fab } from "@mui/material";
import getTechnicals from "../requests/getTechnicals";
import Technicals from "./layers/Technicals";
import BaseModal from "./views/BaseModal";
import Spinner from "../inne/Spinner";
import ElevationFab from "./inputs/ElevationFab";
import useHandleRequest from "../requests/useHandleRequest";
import Notifications from "../inne/Notifications";
import "leaflet/dist/leaflet.css";
import ImageCarousel from "../inne/ImageCarousel";
import LayersIcon from "@mui/icons-material/Layers";
import { GhostIcon } from "../inne/markery";

const WynikiCheckpoint = lazy(() => import("./views/WynikiCheckpoint"));

const EventMap = () => {
	const urls = ["https://c.tile.openstreetmap.org", "https://gpsradar.pl"];

	urls.forEach((url) => {
		if (!document.querySelector(`link[rel="preconnect"][href="${url}"]`)) {
			const preconnectLink = document.createElement("link");
			preconnectLink.rel = "preconnect";
			preconnectLink.href = url;
			document.head.appendChild(preconnectLink);
		}
	});
	const { slug, trackedPlayerIdParam } = useParams();
	const [event, setEvent] = useState({});
	const [players, setPlayers] = useState([]);
	const [route, setRoute] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [checkpoints, setCheckpoints] = useState([]);
	const [trackedPlayerId, setTrackedPlayerId] = useState(null);
	const [technicals, setTechnicals] = useState([]);
	const [mapTileProv, setMapTileProv] = useState(
		"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
	);
	const [ghostPosition, setGhostPosition] = useState([
		52.66257983717545, 19.874267578125004,
	]);

	const [showLayer, setShowLayer] = useState({
		players: true,
		startFinish: true,
		micsMarkers: true,
		technicals: true,
		chackpoints: true,
		route: true,
	});
	const [checkpointState, setCheckpointState] = useState({
		id: null,
		open: false,
		title: "",
	});
	const [topBarOpen, setTopBarOpen] = useState({
		wynikiM: false,
		players: false,
	});
	const mapRef = useRef();
	const handleRequest = useHandleRequest();

	const handleTopBarOpen = (modalType) => {
		setTopBarOpen({
			wynikiM: modalType === "statusy",
			players: modalType === "players",
		});
	};

	const toggleTileProv = () => {
		if (
			mapTileProv === "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		) {
			setMapTileProv(
				"https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
			);
		} else {
			setMapTileProv(
				"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
			);
		}
	};

	const fetchZawodnicy = useCallback(async () => {
		const [success, response] = await getListaZawodnikow(slug);

		if (success) {
			setPlayers(response);
			return response;
		} else {
			return [];
		}
	}, [slug]);

	const handleCheckpointClick = (id) => {
		setCheckpointState({ id: id, open: true, title: "" });
	};

	const trackPlayer = useCallback(
		(players) => {
			if (trackedPlayerId !== null && mapRef.current) {
				const trackedPlayer = players.find(
					(obj) => obj.startNumber === trackedPlayerId,
				);
				if (trackedPlayer && trackedPlayer.deviceStatus) {
					mapRef.current.setView(
						[
							trackedPlayer.deviceStatus.lat,
							trackedPlayer.deviceStatus.lon,
						],
						mapRef.current.getZoom(),
					);
				}
			}
		},
		[trackedPlayerId],
	);

	const locatePlayerFromParam = (players) => {
		if (trackedPlayerIdParam && players) {
			setTrackedPlayerId(parseInt(trackedPlayerIdParam));
			const trackedPlayer = players.find(
				(player) =>
					player.startNumber === parseInt(trackedPlayerIdParam),
			);
			if (trackedPlayer) {
				mapRef.current.setView(
					[
						trackedPlayer.deviceStatus.lat,
						trackedPlayer.deviceStatus.lon,
					],
					20,
					{
						animate: false,
					},
				);
			}
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			const [routeSuccess, routeResponse] = await handleRequest(
				getTrack,
				slug,
			);

			if (routeSuccess) {
				mapRef.current.setView(routeResponse.positions[0].point, 10, {
					animate: false,
				});
				setRoute(routeResponse.positions);
			}
			locatePlayerFromParam(await fetchZawodnicy());
			const [checkpointsSuccess, checkpointsResponse] =
				await getCheckpoints(slug);
			if (checkpointsSuccess) setCheckpoints(checkpointsResponse);
			const [eventSuccess, eventResponse] = await handleRequest(
				getEvent,
				slug,
			);
			if (eventSuccess) 
				setEvent(eventResponse);

				document.title = `${eventResponse.name} - VeloStacja`;

			const [markersSuccess, markersResponse] = await handleRequest(
				getMarkers,
				slug,
			);
			if (markersSuccess) setMarkers(markersResponse);

			const [technicalsSuccess, technicalsResponse] = await handleRequest(
				getTechnicals,
				slug,
			);
			if (technicalsSuccess) 
				setTechnicals(technicalsResponse);
		};
		fetchData();
	}, [slug]);

	useEffect(() => {
		if (event.refreshIntervalInSec) {
			const intervalId = setInterval(async () => {
				trackPlayer(await fetchZawodnicy());

			}, event.refreshIntervalInSec * 1000);
			return () => clearInterval(intervalId);
		}
	}, [event.refreshIntervalInSec, trackedPlayerId]);


	useEffect(() => {
		const technicalInterval = setInterval(() => {
			getTechnicals(slug).then(response => {
				setTechnicals(response[1]);
			})
		}, 60000);
	
		return () => clearInterval(technicalInterval);
	  }, []);


	const closeCheckpoint = () => {
		setCheckpointState({ open: false, id: null, title: "" });
	};


	useEffect(() => {
		console.log(trackedPlayerId);
	}, [trackedPlayerId]);

	return (
		<>
			<>
				<MapContainer
					center={[52.66257983717545, 19.874267578125004]}
					zoom={10}
					scrollWheelZoom={true}
					zoomControl={false}
					preferCanvas={true}
					style={{ width: "100vw", height: "100vh" }}
					ref={mapRef}>
					<TileLayer url={mapTileProv} />

					<LocateUserFab />
					{showLayer.startFinish && (
						<StartFinishMarkers
							track={route}
							handleTopBarOpen={handleTopBarOpen}
						/>
					)}
					{/*ghostPosition && (
						<Marker
							position={ghostPosition}
							icon={GhostIcon()}
						/>
					)*/}
					{showLayer.route && <Polylines track={route} />}
					{showLayer.players && (
						<Players
							players={players}
							event={event}
						/>
					)}
					{showLayer.chackpoints && (
						<Checkpoints
							checkpoints={checkpoints}
							onClick={handleCheckpointClick}
						/>
					)}
					{showLayer.micsMarkers && <Markers markers={markers} />}
					{showLayer.technicals && (
						<Technicals technicals={technicals} />
					)}
					<TopBar
						players={players}
						setTrackedPlayerId={setTrackedPlayerId}
						trackedPlayerId={trackedPlayerId}
						checkpoints={checkpoints}
						event={event}
						topBarOpen={topBarOpen}
						setTopBarOpen={setTopBarOpen}
						handleTopBarOpen={handleTopBarOpen}
					/>
				</MapContainer>

				<Notifications />

				<ImageCarousel event={event} />

				<ElevationFab
					route={route}
					players={players}
					checkpoints={checkpoints}
				/>
				<LayersFab
					showLayer={showLayer}
					setShowLayer={setShowLayer}
				/>

				<Fab
					size="large"
					color="primary"
					onClick={toggleTileProv}
					sx={{
						position: "fixed",
						bottom: 172,
						right: 16,
						zIndex: 999,
					}}>
					<LayersIcon />
				</Fab>

				<BaseModal
					title={checkpointState.title}
					open={checkpointState.open}
					onClose={closeCheckpoint}>
					<Suspense fallback={<Spinner />}>
						<WynikiCheckpoint
							wynikiCheckpointState={checkpointState}
							setWynikiCheckpointState={setCheckpointState}
							event={event}
						/>
					</Suspense>
				</BaseModal>
			</>
		</>
	);
};

export default EventMap;
