import React, { useRef, useState, useEffect, Fragment } from "react";
import {
	MapContainer,
	TileLayer,
	Marker,
	useMap,
	Circle,
	Tooltip,
	useMapEvents,
} from "react-leaflet";
import getTrack from "../requests/getTrack";
import Polylines from "../mapa/layers/Polylines";
import {
	Stack,
	Button,
	Box,
	TextField,
	IconButton,
	Input,
	Paper,
	Typography,
	FormControl,
} from "@mui/material";
import { checkpointIcon, iconTemplates, MicsMarkerIcon } from "../inne/markery";
import Qicon from "../media/question-sign.png";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit, LocationOn } from "@mui/icons-material";
import useHandleRequest from "../requests/useHandleRequest";
import getMarkers from "../requests/getMarkers";
import getCheckpoints from "../requests/getCheckpoints";
import postAddCheckpoint from "../requests/postAddCheckpoint";
import deleteCheckpoint from "../requests/deleteCheckpoint";
import postAddMarkerToEvent from "../requests/postAddMarkerToEvent";
import deleteMarker from "../requests/deleteMarker";

const styles = {
	noFileTypography: {
		mt: 1,
		textWrap: "wrap",
	},
	mapStack: { width: "100%", height: "700px" },
	mapContainer: {
		width: "100%",
		height: "100%",
	},
	pointBox: {
		borderRadius: "16px",
		padding: 2,
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
		bgcolor: "background.paper",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	pointsListStack: {
		overflowX: "auto",
		maxHeight: 400,
		padding: 2,
		pt: "20px",
	},
	pointsListPaper: {
		overflowX: "auto",
		maxheight: 400,
		padding: 2,
	},
	stackContainer: {
		width: "100%",
		height: "100%",
	},
	buttonBox: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "relative",
		width: "80px",
		paddingLeft: "18px",
		paddingTop: "18px",
		boxSizing: "border-box",
	},
	formStack: {
		mx: 2,
		mt: 1,
	},
	textField: {
		width: "308px",
	},
	rowStack: {
		maxWidth: "200px",
	},
	saveButton: {
		mt: 2,
		maxWidth: 150,
	},
};

function withCenterTracking(WrappedComponent) {
	return function CenterTrackingComponent(props) {
		const map = useMap();
		const [center, setCenter] = useState(map.getCenter());

		useEffect(() => {
			const updateCenter = () => setCenter(map.getCenter());
			map.on("move", updateCenter);
			map.on("zoom", updateCenter);
			return () => {
				map.off("move", updateCenter);
				map.off("zoom", updateCenter);
			};
		}, [map]);

		return (
			<WrappedComponent
				{...props}
				center={center}
			/>
		);
	};
}

const CenterCheckpoint = withCenterTracking(({ center, circleRadius }) => (
	<>
		<Marker
			icon={checkpointIcon("?")}
			position={center}
		/>
		<Circle
			center={center}
			pathOptions={{ color: "red" }}
			radius={circleRadius}
		/>
	</>
));

const CenterMarker = withCenterTracking(({ center, icon }) => (
	<Marker
		icon={icon}
		position={center}
	/>
));

function MapEditor({ selectedEvent }) {
	const handleRequest = useHandleRequest();
	const [route, setRoute] = useState([]);
	const [isMarkerFormDisabled, setMarkerFormDisabled] = useState(true);
	const [markerFormError, setMarkerFormError] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [routeKm, setRouteKm] = useState("");
	const [checkpointRadius, setCheckpointRadius] = useState(250);
	const [uploadedIconSrc, setUploadedIconSrc] = useState(Qicon);
	const [addedCheckpoints, setAddedCheckpoints] = useState([]);
	const [addedMarkers, setAddedMarkers] = useState([]);
	const [isCheckpointFormDisabled, setCheckpointFormDisabled] =
		useState(true);
	const [coords, setCoords] = useState({
		lat: 52.66257983717545,
		lon: 19.874267578125004,
		zoom: 10,
	});
	const [checkpointFormText, setCheckpointFormText] = useState({
		name: "",
		nmbr: 1,
		id: 0
	});
	const [markerFormText, setMarkerFormText] = useState({
		name: "",
		desc: "",
		id: 0
	});
	const mapRef = useRef();

	function MapEventHandler() {
		const map = useMapEvents({
			moveend: () => {
				const center = map.getCenter();
				setCoords({
					lat: center.lat,
					lon: center.lng,
					zoom: map.getZoom(),
				});
				setRouteKm("");
			},
		});

		return null;
	}

	const handleUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setUploadedIconSrc(reader.result);
			};
			reader.readAsDataURL(file);
			setUploadedFile(file);
			setMarkerFormDisabled(false);
			setMarkerFormError(false);

			if (!isMarkerFormDisabled) {
				setCheckpointFormDisabled(true);
			}
		} else {
			setUploadedFile(null);
			setMarkerFormDisabled(true);
			setMarkerFormError(true);
		}
	};

	const toggleCheckpointForm = () => {
		if (isCheckpointFormDisabled) {
			setMarkerFormDisabled(true);
		}
		setCheckpointFormDisabled(!isCheckpointFormDisabled);
	};

	const toggleMarkerForm = () => {
		if (isMarkerFormDisabled) {
			setCheckpointFormDisabled(true);
		}
		setMarkerFormDisabled(!isMarkerFormDisabled);
	};

	const handleLatLonChange = (e) => {
		const { value, name } = e.target;
		if (mapRef.current) {
			mapRef.current.setView(
				[
					name === "lat" ? value : coords.lat,
					name === "lon" ? value : coords.lon,
				],
				coords.zoom,
				{ animate: false },
			);
		}

		setCoords((prev) => ({ ...prev, [name]: value }));
	};

	/*
	const handleDelete = (index, type) => {
			const setFunction = type === "marker" ? setAddedMarkers : setAddedCheckpoints;
			setFunction((prev) => {
					const updatedArray = prev.slice();
					updatedArray.splice(index, 1);
					return updatedArray;
			});

			mapRef.current.setView(coords, coords.zoom, { animate: false });
	};*/

	const removeCheckpoint = (id) => {
		const removeItem = async() => 
			{
				const [removeSuccess, response] = await handleRequest(deleteCheckpoint, id);
				if(removeSuccess) {
					setAddedCheckpoints((prevCheckpoints) =>
						prevCheckpoints.filter((checkpoint) => checkpoint.id !== id)
					);
				}
			}

		removeItem();
	}

	const removeMarker = (id) => {
		const removeItem = async() => 
			{
				const [removeSuccess, response] = await handleRequest(deleteMarker, id);
				if(removeSuccess) {
					setAddedMarkers((prevMarkers) =>
						prevMarkers.filter((marker) => marker.id !== id)
					);
				}
			}

		removeItem();
	}

	const saveCheckpointForm = (event) => {
		event.preventDefault();

		const addCheckpoint = async () => 
			{
			const [addCheckpointSuccess, addCheckpointResponse] = await handleRequest(postAddCheckpoint,
					{
						id: checkpointFormText.id,
						eventSlug: selectedEvent.slug,
						lat: coords.lat,
						lon: coords.lon,
						name: checkpointFormText.name,
						radiusKm: checkpointRadius/1000,
						orderPoint: checkpointFormText.nmbr
					});

				if(addCheckpointSuccess) {
					setCheckpointFormDisabled(true);
					setAddedCheckpoints((prevCheckpoints) =>
						prevCheckpoints.map((checkpoint) =>
							checkpoint.id === addCheckpointResponse.id
								? {
									...checkpoint,
									position: [coords.lat, coords.lon],
									name: checkpointFormText.name,
									nmbr: checkpointFormText.nmbr,
									radius: checkpointRadius,
								}
								: checkpoint
						).concat(
							prevCheckpoints.some(checkpoint => checkpoint.id === addCheckpointResponse.id) ? [] : [{
								id: addCheckpointResponse.id,
								position: [coords.lat, coords.lon],
								name: checkpointFormText.name,
								nmbr: checkpointFormText.nmbr,
								radius: checkpointRadius,
							}]
						)
					);

					setCheckpointFormText((prev) => ({ ...prev, name: "", id: 0 }));
				}
			}

		addCheckpoint();
	};

	const saveMarkerForm = (event) => {
		event.preventDefault();

		if (!uploadedFile) {
			setMarkerFormError(true);
			return;
		}

		const addMarkerAsync = async () => 
			{
				const formData = new FormData();
				formData.append('file', uploadedFile);
				formData.append('iconDTO', new Blob([JSON.stringify({
					eventSlug: selectedEvent.slug,
					name: markerFormText.name,
					lat: coords.lat,
					lon: coords.lon
				})], { type: 'application/json' }));

				const [isSuccess, response] = await handleRequest(postAddMarkerToEvent, formData);

				if(isSuccess) {

					console.log(response);

					setMarkerFormDisabled(true);
					setAddedMarkers([
						...addedMarkers,
						{
							position: [coords.lat, coords.lon],
							content: uploadedIconSrc,
							name: markerFormText.name,
							desc: markerFormText.desc,
						},
					]);
				}
			}

		addMarkerAsync();
	};

	useEffect(() => {
		const fetchData = async () => {
			const [routeSuccess, routeResponse] = await handleRequest(
				getTrack,
				selectedEvent.slug,
			);
			if (routeSuccess) {
				mapRef.current.setView(routeResponse.positions[0].point, 10, {
					animate: false,
				});
				setRoute(routeResponse.positions);
			}
			const [markerySuccess, markeryResponse] = await handleRequest(
				getMarkers,
				selectedEvent.slug,
			);
			if (markerySuccess) {
				setAddedMarkers(
					markeryResponse.map((marker) => ({
						id: marker.id,
						position: [marker.lat, marker.lon],
						content: marker.fileName,
						name: marker.name,
						desc: "",
					})),
				);
			}
			const [checkpointySuccess, checkpointyResponse] = await handleRequest(getCheckpoints, selectedEvent.slug);

			if (checkpointySuccess) {
				setAddedCheckpoints(
					checkpointyResponse.map((checkpoint) => ({
						id: checkpoint.id,
						position: [checkpoint.lat, checkpoint.lon],
						name: checkpoint.name,
						nmbr: checkpoint.orderPoint,
						radius: checkpoint.radiusKm * 1000,
					})),
				);
			}
		};
		fetchData();
	}, [selectedEvent.slug]);

	function findClosestRoutePoint() {
		if (route.length === 0) {
			return;
		}

		const point = route.reduce((closest, current) => {
			if (
				!current.hasOwnProperty("dist") ||
				!current.hasOwnProperty("point")
			) {
				return closest;
			}

			if (
				!closest.hasOwnProperty("dist") ||
				!closest.hasOwnProperty("point")
			) {
				return current;
			}

			return Math.abs(current.dist - routeKm) <
				Math.abs(closest.dist - routeKm)
				? current
				: closest;
		}, {});

		if (!point.point) {
			return;
		}
		mapRef.current.setView(point.point, coords.zoom);
		setCoords((prev) => ({
			...prev,
			lat: point.point[0],
			lon: point.point[1],
		}));
	}
	return (
		<Stack
			direction="row"
			spacing={6}>
			<Stack
				sx={styles.mapStack}
				spacing={2}
				direction="column">
				<MapContainer
					center={[coords.lat, coords.lon]}
					zoom={coords.zoom}
					scrollWheelZoom={true}
					zoomControl={false}
					preferCanvas={true}
					style={styles.mapContainer}
					ref={mapRef}>
					<TileLayer
						url={
							"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						}
					/>

					{!isMarkerFormDisabled && (
						<CenterMarker icon={MicsMarkerIcon(uploadedIconSrc)} />
					)}
					{!isCheckpointFormDisabled && (
						<CenterCheckpoint circleRadius={checkpointRadius} />
					)}
					<Polylines track={route} />
					<MapEventHandler />
					{addedCheckpoints.map((checkpoint, index) => (
						<Fragment key={`checkpoint ${index}`}>
							<Marker
								icon={checkpointIcon(checkpoint.nmbr)}
								position={checkpoint.position}
								key={`Marker: ${index}`}>
								<Tooltip>{checkpoint.name}</Tooltip>
							</Marker>
							<Circle
								center={checkpoint.position}
								pathOptions={{ color: "red" }}
								radius={checkpoint.radius}
							/>
						</Fragment>
					))}
					{addedMarkers.map((marker, index) => (
						<Marker
							icon={MicsMarkerIcon(`${process.env.PUBLIC_URL}images/markers/${marker.content}`)}
							position={marker.position}
							key={`Marker: ${index}`}>
							<Tooltip>
								<Typography>{marker.name}</Typography>
								{marker.desc}
							</Tooltip>
						</Marker>
					))}
				</MapContainer>
				<Stack
					direction="row"
					spacing={3}>
					<Stack>
						<Typography
							color={
								isMarkerFormDisabled
									? "text.secondary"
									: "text.primary"
							}>
							Podaj kilometr trasy:
						</Typography>
						<Stack
							direction="row"
							spacing={1}>
							<TextField
								label="Km. trasy"
								value={routeKm}
								onChange={(e) => setRouteKm(e.target.value)}
								type="number"
								size="small"
								sx={styles.textField}
							/>
							<Button
								variant="contained"
								onClick={findClosestRoutePoint}>
								Idź
							</Button>
						</Stack>
					</Stack>
					<Stack>
						<Typography
							color={
								isMarkerFormDisabled
									? "text.secondary"
									: "text.primary"
							}>
							lub zaznacz na mapie:
						</Typography>
						<Stack
							direction="row"
							spacing={1}>
							<TextField
								label="Lat"
								name="lat"
								type="number"
								value={coords.lat}
								sx={styles.rowStack}
								size="small"
								onChange={handleLatLonChange}
							/>
							<TextField
								label="Lon"
								name="lon"
								size="small"
								type="number"
								value={coords.lon}
								onChange={handleLatLonChange}
								sx={styles.rowStack}
							/>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Stack
				sx={styles.stackContainer}
				direction="row"
				justifyContent="space-around"
				width="100%"
				spacing={4}>
				<Stack
					m={1}
					direction="column"
					spacing={2}>
					<Stack direction="row">
						<Button
							variant="contained"
							onClick={toggleCheckpointForm}>
							dodaj checkpoint
						</Button>
						<Box
							sx={styles.buttonBox}
							dangerouslySetInnerHTML={{
								__html: iconTemplates.checkpointIconHtml("?"),
							}}
						/>
					</Stack>

					<FormControl disabled={isCheckpointFormDisabled}>
						<Box
							component="form"
							onSubmit={saveCheckpointForm}>
							<Stack
								direction="column"
								sx={styles.formStack}>
								<Stack>
									<Typography
										color={
											isCheckpointFormDisabled
												? "text.secondary"
												: "text.primary"
										}>
										dodaj nazwę:
									</Typography>
									<TextField
										name="nazwa"
										label="Nazwa"
										size="small"
										value={checkpointFormText.name}
										onChange={(e) => {
											setCheckpointFormText((prev) => ({
												...prev,
												name: e.target.value,
											}));
										}}
										required
										sx={styles.textField}
										disabled={isCheckpointFormDisabled}
									/>
								</Stack>
								<Stack>
									<Typography
										color={
											isCheckpointFormDisabled
												? "text.secondary"
												: "text.primary"
										}>
										numer checkpointu:
									</Typography>
									<TextField
										label="Numer"
										name="numer"
										size="small"
										type="number"
										required
										value={checkpointFormText.nmbr}
										onChange={(e) => {
											setCheckpointFormText((prev) => ({
												...prev,
												nmbr: e.target.value,
											}));
										}}
										sx={styles.textField}
										disabled={isCheckpointFormDisabled}
									/>
								</Stack>
								<Stack>
									<Typography
										color={
											isCheckpointFormDisabled
												? "text.secondary"
												: "text.primary"
										}>
										promień checkpointu (m):
									</Typography>
									<TextField
										label="Promień (m)"
										size="small"
										name="promien"
										type="number"
										value={checkpointRadius}
										onChange={(e) => {
											setCheckpointRadius(e.target.value);
										}}
										required
										sx={styles.textField}
										disabled={isCheckpointFormDisabled}
									/>
								</Stack>

								<Button
									variant="contained"
									type="submit"
									disabled={isCheckpointFormDisabled}
									sx={styles.saveButton}>
									Zapisz
								</Button>
							</Stack>
						</Box>
					</FormControl>
					<Paper sx={styles.pointsListPaper}>
						<Typography
							variant="h6"
							gutterBottom>
							Checkpoint'y
						</Typography>
						{addedCheckpoints.length === 0 ? (
							<Typography>Brak dodanych Checkpoint'ów</Typography>
						) : (
							<Stack
								spacing={2}
								pt={"20px"}
								sx={styles.pointsListStack}>
								{addedCheckpoints.map((point, index) => (
									<Box
										key={`checkpoint-list-element-${index}`}
										sx={styles.pointBox}>
										<Box>
											<Typography
												variant="subtitle1"
												color="text.primary">
												{point.name}
											</Typography>
											<Stack
												direction="row"
												spacing={2}
												flexWrap="wrap">
												<Typography
													variant="body2"
													color="text.secondary">
													Numer: {point.nmbr}
												</Typography>
												<Typography
													variant="body2"
													color="text.secondary">
													Promien: {point.radius}
												</Typography>
											</Stack>
										</Box>
										<Stack direction="row">
											<IconButton
												edge="end"
												onClick={() => {
													mapRef.current.flyTo(point.position, 12);
												}}>
												<LocationOn />
											</IconButton>
											<IconButton
												edge="end"
												onClick={() => {
													removeCheckpoint(point.id);
												}}>
												<DeleteIcon />
											</IconButton>
										</Stack>
									</Box>
								))}
							</Stack>
						)}
					</Paper>
				</Stack>
				<Stack
					direction="column"
					spacing={2}
					m={1}>
					<Stack direction="row">
						<Button
							variant="contained"
							sx={{ mr: 1 }}
							onClick={toggleMarkerForm}>
							dodaj marker
						</Button>

						<Box
							sx={styles.buttonBox}
							dangerouslySetInnerHTML={{
								__html: iconTemplates.MicsMarkerIconHtml(
									uploadedIconSrc,
								),
							}}
						/>
					</Stack>

					<FormControl disabled={isMarkerFormDisabled}>
						<Box
							component="form"
							onSubmit={saveMarkerForm}>
							<Stack
								direction="column"
								sx={styles.formStack}>
								{" "}
								<label htmlFor="upload-icon">
									<Input
										id="upload-icon"
										type="file"
										accept=".svg, .jpg .jpeg .png"
										sx={{ display: "none" }}
										onChange={handleUpload}
									/>
									<Button
										variant="outlined"
										component="span"
										fullWidth
										sx={{ marginTop: 3 }}
										disabled={isMarkerFormDisabled}
										startIcon={<UploadIcon />}>
										Przekaz ikone
									</Button>
								</label>
								<Stack>
									<Typography
										color={
											isMarkerFormDisabled
												? "text.secondary"
												: "text.primary"
										}>
										dodaj nazwę:
									</Typography>
									<TextField
										value={markerFormText.name}
										onChange={(e) => {
											setMarkerFormText((prev) => ({
												...prev,
												name: e.target.value,
											}));
										}}
										label="Nazwa"
										required
										name="nazwa"
										size="small"
										sx={styles.textField}
										disabled={isMarkerFormDisabled}
									/>
								</Stack>
								<Stack>
									<Typography
										color={
											isMarkerFormDisabled
												? "text.secondary"
												: "text.primary"
										}>
										i opis:
									</Typography>
									<TextField
										value={markerFormText.desc}
										onChange={(e) => {
											setMarkerFormText((prev) => ({
												...prev,
												desc: e.target.value,
											}));
										}}
										label="Opis"
										required
										name="opis"
										size="small"
										sx={styles.textField}
										disabled={isMarkerFormDisabled}
									/>
								</Stack>
								<Button
									variant="contained"
									type="submit"
									disabled={isMarkerFormDisabled}
									sx={styles.saveButton}>
									Zapisz
								</Button>
							</Stack>
						</Box>
					</FormControl>
					{markerFormError && (
						<Typography
							color="error"
							sx={styles.noFileTypography}>
							Dodaj ikonę zanim ustawisz marker!
						</Typography>
					)}
					<Paper sx={styles.pointsListPaper}>
						<Typography
							variant="h6"
							gutterBottom>
							Markery
						</Typography>
						{addedMarkers.length === 0 ? (
							<Typography>Brak dodanych Markerów</Typography>
						) : (
							<Stack
								spacing={2}
								sx={styles.pointsListStack}>
								{addedMarkers.map((point, index) => (
									<Box
										key={`marker-list-element-${index}`}
										sx={styles.pointBox}>
										<Box>
											<Typography
												variant="subtitle1"
												color="text.primary">
												{point.name}
											</Typography>
											<Stack
												direction="row"
												spacing={2}
												flexWrap="wrap">
												<Typography
													variant="body2"
													color="text.secondary">
													Opis: {point.desc}
												</Typography>
											</Stack>
										</Box>
										<Stack direction="row">
											<IconButton
												edge="end"
												onClick={() => {
													mapRef.current.flyTo(point.position, 12);
												}}>
												<LocationOn />
											</IconButton>
											<IconButton
												edge="end"
												onClick={() => {
													removeMarker(point.id)
												}}>
												<DeleteIcon />
											</IconButton>
										</Stack>
									</Box>
								))}
							</Stack>
						)}
					</Paper>
				</Stack>
			</Stack>
		</Stack>
	);
}

export default MapEditor;
