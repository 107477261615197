import React, { useState, useEffect } from "react";
import {
	Box,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	Stack,
	Input,
	ListItemText,
	OutlinedInput,
	Paper,
	Typography,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import useHandleRequest from "../requests/useHandleRequest";
import getAllEvents from "../requests/getAllEvents";
import useNotifications from "../inne/useNotifications";

const styles = {
	form: {
		display: "flex",
		flexDirection: "column",
		gap: 2,
		maxWidth: 600,
		flexGrow: 1,
	},
	formSection: {
		width: "100%",
	},
	row: {
		width: "100%",
	},
	column: {
		width: "100%",
		spacing: 1.5,
	},
	button: {
		height: 40,
	},
	uploadLabel: {
		margin: 1,
		width: "30%",
	},
	select: {
		margin: 1,
		width: "70%",
	},
	paper: {
		padding: 2,
		width: 300,
		height: "100%",
		overflowY: "auto",
	},
	submittedItem: {
		borderRadius: "16px",
		padding: 2,
		boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
		backgroundColor: "background.paper",
		display: "flex",
		flexDirection: "column",
		gap: 1,
	},
};

export default function GpsTechniczne({ selectedEvent }) {
	const addNotification = useNotifications();

	const handleRequest = useHandleRequest();
	const [events, setEvents] = useState([]);
	const [formData, setFormData] = useState({
		name: "",
		deviceName: "",
		desc: "",
		image: null,
		selectedOptions: [],
	});
	const [submittedItems, setSubmittedItems] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const [eventsSuccess, eventsResponse] = await handleRequest(
				getAllEvents,
				null,
			);
			if (eventsSuccess) setEvents(eventsResponse);
		};
		fetchData();
	}, []);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			setFormData((prev) => ({ ...prev, image: file }));
		} else {
			setFormData((prev) => ({ ...prev, image: null }));
		}
	};

	const handleSelectChange = (event) => {
		const {
			target: { value },
		} = event;
		setFormData({
			...formData,
			selectedOptions:
				typeof value === "string" ? value.split(",") : value,
		});
	};

	const handleSubmit = (e) => {
		addNotification('Zapisano Zmiany!')
		e.preventDefault();
		setSubmittedItems([...submittedItems, formData]);
		setFormData({
			name: "",
			deviceName: "",
			desc: "",
			image: null,
			selectedOptions: [],
		});
	};

	return (
		<Stack
			direction="row"
			spacing={2}
			width="80%">
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={styles.form}>
				<Stack
					direction="column"
					spacing={2}
					sx={styles.formSection}>
					<Stack
						direction="row"
						sx={styles.row}
						spacing={2}>
						<Stack
							spacing={1.5}
							sx={styles.column}>
							<TextField
								label="Nazwa"
								name="name"
								required
								fullWidth
								value={formData.name}
								onChange={handleChange}
							/>
							<TextField
								label="Device Name"
								name="deviceName"
								required
								fullWidth
								value={formData.deviceName}
								onChange={handleChange}
							/>
						</Stack>
						<TextField
							label="Opis"
							name="desc"
							required
							fullWidth
							multiline
							rows={4}
							value={formData.desc}
							onChange={handleChange}
						/>
					</Stack>
					<Stack
						direction="row"
						spacing={1}>
						<label
							htmlFor="icon"
							style={styles.uploadLabel}>
							<Input
								id="icon"
								name="icon"
								type="file"
								accept=".svg, .jpg .jpeg .png"
								onChange={handleImageChange}
								sx={{ display: "none" }}
							/>
							<Button
								sx={styles.button}
								variant="outlined"
								component="span"
								fullWidth
								startIcon={<UploadIcon />}>
								Wgraj ikonę
							</Button>
						</label>
						<FormControl sx={styles.select}>
							<InputLabel id="multiple-checkbox-label">
								Wybierz zawody
							</InputLabel>
							<Select
								labelId="multiple-checkbox-label"
								id="multiple-checkbox"
								multiple
								required
								size="small"
								value={formData.selectedOptions}
								onChange={handleSelectChange}
								input={<OutlinedInput label="Select Options" />}
								renderValue={(selected) =>
									selected.map((item) => item.name).join(", ")
								}>
								{events.map((event) => (
									<MenuItem
										key={event.slug}
										value={event}>
										<Checkbox
											checked={
												formData.selectedOptions.indexOf(
													event,
												) > -1
											}
										/>
										<ListItemText primary={event.name} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Stack>
					<Button
						type="submit"
						variant="contained"
						color="primary">
						Dodaj
					</Button>
				</Stack>
			</Box>

			<Paper sx={styles.paper}>
				<Typography
					variant="h6"
					gutterBottom>
					GPS'y techniczne
				</Typography>
				{submittedItems.length === 0 ? (
					<Typography>Brak GPS'ów</Typography>
				) : (
					<Stack spacing={2}>
						{submittedItems.map((item, index) => (
							<Box
								key={`submitted-item-${index}`}
								sx={styles.submittedItem}>
								<Typography
									variant="subtitle1"
									color="text.primary">
									<strong>Name:</strong> {item.name}
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary">
									<strong>Device Name:</strong>{" "}
									{item.deviceName}
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary">
									<strong>Description:</strong> {item.desc}
								</Typography>
								<Typography
									variant="body2"
									color="text.secondary">
									<strong>Selected Options:</strong>{" "}
									{item.selectedOptions
										.map((option) => option.name)
										.join(", ")}
								</Typography>
							</Box>
						))}
					</Stack>
				)}
			</Paper>
		</Stack>
	);
}
