import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Container, Typography, Box } from "@mui/material";

const NotFoundPage = () => {
	const navigate = useNavigate();

	const handleGoHome = () => {
		navigate("/");
	};

	const styles = {
		container: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center",
			minHeight: "100vh",
			textAlign: "center",
			padding: "2rem",
		},
		box: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		typographyH1: {
			fontSize: "6rem",
			fontWeight: "bold",
			color: "#f44336",
			marginBottom: "1rem",
		},
		typographyH5: {
			marginBottom: "2rem",
			color: "#333",
		},
		button: {
			fontSize: "1rem",
			padding: "0.75rem 1.5rem",
		},
	};

	return (
		<Container
			maxWidth="sm"
			sx={styles.container}
			role="main">
			<Box sx={styles.box}>
				<Typography
					id="not-found-title"
					variant="h1"
					component="h1"
					sx={styles.typographyH1}>
					404
				</Typography>
				<Typography
					variant="h5"
					component="p"
					sx={styles.typographyH5}>
					Ups! Strona, której szukasz, nie istnieje.
				</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={handleGoHome}
					sx={styles.button}>
					Wróć na stronę główną
				</Button>
			</Box>
		</Container>
	);
};

export default NotFoundPage;
