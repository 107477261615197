import React, { Suspense, useState, lazy } from "react";
import { Fab, Tooltip } from "@mui/material";
import Terrain from "@mui/icons-material/Terrain";
import Spinner from "../../inne/Spinner";
import ElevationModal from "../views/ElevationModal";

const styles = {
	position: "fixed",
	bottom: 248,
	right: 16,
	zIndex: 999,
};

const ElevationChart = lazy(() => import("../views/ElevationChart"));

const ElevationFab = ({ route, players, checkpoints }) => {
	const [open, setOpen] = useState(false);

	const toggleModal = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	return (
		<>
			<Tooltip title="Profil Wysokościowy">
				<Fab
					size="large"
					color="primary"
					onClick={toggleModal}
					sx={styles}>
					<Terrain />
				</Fab>
			</Tooltip>
			<ElevationModal
				open={open}
				onClose={toggleModal}>
				<Suspense fallback={<Spinner />}>
					<ElevationChart
						route={route}
						players={players}
						checkpoints={checkpoints}
					/>
				</Suspense>
			</ElevationModal>
		</>
	);
};

export default ElevationFab;
