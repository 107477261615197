import React, { useMemo } from "react";
import { Polyline, Tooltip } from "react-leaflet";

const PolylineComponent = React.memo(({ positions, tooltipText }) => (
	<Polyline
		positions={positions}
		color="#222"
		weight={6}>
		<Tooltip
			direction="top"
			offset={[0, -10]}
			opacity={1}
			sticky>
			{tooltipText}
		</Tooltip>
	</Polyline>
));

function Polylines({ track }) {
	const memoizedPolylines = useMemo(() => {
		if (!track || track.length <= 1) return [];
		return track.map((lineStart, index) => {
			if (track.length > index + 1) {
				return (
					<PolylineComponent
						key={`polyline-${index}`}
						positions={[lineStart.point, track[index + 1].point]}
						tooltipText={` ${lineStart.dist} km trasy`}
					/>
				);
			}
			return null;
		});
	}, [track]);

	return <>{memoizedPolylines}</>;
}

Polylines.displayName = "Polylines";

export default Polylines;
