import React, { useState, useEffect } from "react";
import {
	Modal,
	Box,
	TextField,
	Button,
	FormControl,
	FormControlLabel,
	Checkbox,
	OutlinedInput,
	ListItemText,
	InputLabel,
	MenuItem,
	Stack,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText
} from "@mui/material";
import useNotifications from "../inne/useNotifications";
import SelectDeviceComponent from "./components/SelectDeviceComponent";

const styles = {
	modalBox: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 500,
		bgcolor: "background.paper",
		border: "2px solid #000",
		boxShadow: 24,
		p: 3,
	},
	sectionStack: {
		direction: "row",
		spacing: 1,
		my: 1,
	},
	formControl: {
		my: 0.5,
		width: "100%",
	},
	buttonBox: {
		mt: 2,
		display: "flex",
		justifyContent: "flex-end",
	},
};

const EditCompetitorModal = ({
	open,
	handleClose,
	competitor,
	event,
	onSave,
	rowsLength,
}) => {
	const addNotification = useNotifications();
	const [formValues, setFormValues] = useState({
		id: 0,
		firstName: "",
		lastName: "",
		female: false,
		teamName: "",
		city: "",
		nrTel: "",
		startNumber: "",
		groupNumber: "",
		deviceName: "",
		timeStart: "",
		categories: [],
	});

	const emptyForm = () => {
		setFormValues({
			id: 0,
			firstName: "",
			lastName: "",
			female: false,
			teamName: "",
			city: "",
			nrTel: "",
			startNumber: "",
			groupNumber: "",
			deviceName: "",
			timeStart: "",
			categories: [],
		});
	};

	useEffect(() => {
		if (competitor) {
			setFormValues({
				id: competitor.id || 0,
				firstName: competitor.name.split(" ")[0] || "",
				lastName: competitor.name.split(" ")[1] || "",
				female: competitor.female || false,
				teamName: competitor.teamName || "",
				city: competitor.city || "",
				nrTel: competitor.phoneNumber || "",
				startNumber: competitor.startNumber || "",
				groupNumber:
					competitor.groupNumber !== null
						? competitor.groupNumber
						: "",
				deviceName: competitor.device?.name || "",
				timeStart: competitor.timeStart || "",
				categories: competitor.categories || [],
			});
		} else {
			emptyForm();
		}
	}, [competitor]);

	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;
		setFormValues({
			...formValues,
			[name]: type === "checkbox" ? checked : value,
		});
	};
	const handleCategoryChange = (event) => {
		const { value } = event.target;
		setFormValues({
			...formValues,
			categories: typeof value === "string" ? value.split(",") : value,
		});
	};

	const handleDevice = (device) => {
		console.log(device);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		const updatedCompetitor = {
			...competitor,
			...formValues,
			name: `${formValues.firstName} ${formValues.lastName}`,
			id: competitor ? competitor.id : 0,
			deviceName: formValues.deviceName,
		};

		console.log(updatedCompetitor);

		emptyForm();
		onSave(updatedCompetitor);
		addNotification(
			`${competitor ? "Zmieniono" : "Dodano"} uczestnika Nr.: ${
				formValues.startNumber
			}`,
			"success",
		);
	};

	const onClose = () => {
		handleClose();
		emptyForm();
	};

	return (
		<Dialog
			scroll="paper"  // Umożliwia przewijanie
			fullWidth      // Pełna szerokość okna modalu
			maxWidth="md"
			open={open}
			onClose={onClose}>
			<DialogTitle>Panel zawodnika</DialogTitle>
			<DialogContent dividers>
			<Box
				component="form"
				onSubmit={handleSubmit}
				>
				<Stack sx={styles.sectionStack}>
					<TextField
						name="firstName"
						label="Imię"
						required
						value={formValues.firstName}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						name="lastName"
						label="Nazwisko"
						required
						value={formValues.lastName}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
				</Stack>
				<Stack
					sx={styles.sectionStack}
					width="100%">
					<Stack
						width="100%"
						direction="row"
						spacing={1}>
						<TextField
							name="startNumber"
							label="Nr. Start"
							value={formValues.startNumber}
							onChange={handleChange}
							margin="normal"
							fullWidth
							type="number"
						/>
						<TextField
							name="groupNumber"
							label="Nr. grupy"
							value={formValues.groupNumber}
							onChange={handleChange}
							margin="normal"
							type="number"
							fullWidth
						/>
					</Stack>
					<TextField
						name="teamName"
						label="Team"
						value={formValues.teamName}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
				</Stack>
				<Stack sx={styles.sectionStack}>
					<TextField
						name="nrTel"
						label="Numer Tel."
						value={formValues.nrTel}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						name="city"
						label="Miasto"
						value={formValues.city}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
				</Stack>
				<Stack sx={styles.sectionStack}>
					<SelectDeviceComponent onDeviceSelect={handleDevice} deviceName={formValues.deviceName} />
					<TextField
						name="deviceName"
						label="Device Name"
						value={formValues.deviceName}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
					<TextField
						name="timeStart"
						label="Czas Startu"
						value={formValues.timeStart}
						onChange={handleChange}
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						type="datetime-local"
						margin="normal"
					/>
				</Stack>
				<Stack>
					<FormControl sx={styles.formControl}>
						<InputLabel>Kategoria</InputLabel>
						<Select
							name="categories"
							multiple
							value={formValues.categories}
							onChange={handleCategoryChange}
							input={<OutlinedInput label="Kategoria" />}
							renderValue={(selected) =>
								selected.map((cat) => cat).join(", ")
							}>
							{event.competitorCategory?.map((item) => (
								<MenuItem
									key={`cat: ${item.id}`}
									value={item.id}>
									<Checkbox
										checked={
											formValues.categories.indexOf(
												item.id,
											) > -1
										}
									/>
									<ListItemText primary={item.id} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControlLabel
						control={
							<Checkbox
								checked={formValues.female}
								onChange={handleChange}
								name="female"
							/>
						}
						label="Kobieta"
					/>
				</Stack>
			</Box>
			</DialogContent>
			<DialogActions>
			<Box sx={styles.buttonBox}>
					<Button
						onClick={onClose}
						sx={{ mr: 1 }}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="contained"
						color="primary">
						Submit
					</Button>
				</Box>
			</DialogActions>
		</Dialog>
	);
};

export default EditCompetitorModal;
