import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Box,
	Tabs,
	Tab,
	Typography,
	Paper,
	AppBar,
	Toolbar,
	TextField,
	Stack,
	Divider,
	Button
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import ExitToApp from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import useHandleRequest from "../requests/useHandleRequest";
import getAllEvents from "../requests/getAllEvents";
import AddEventForm from "./AddEventForm";
import SettingsEventForm from "./SettingsEventForm";
import Competitors from "./Competitors";
import MapEditor from "./MapEditor";
import Notifications from "../inne/Notifications";
import GpsTechniczne from "./GpsTechniczne";

const styles = {
	appBar: {
		position: "relative",
	},
	toolbar: {
		display: "flex",
	},
	logoutButton: {
		marginLeft: 'auto',
	},
	tabPanel: {
		padding: 4,
		maxWidth: "calc(100vw - 329px)",
		maxHeight: "calc(100vh - 128px)",
	},
	paper: {
		width: 240,
		display: "flex",
		height: "calc(100vh - 82px)",
		flexDirection: "column",
		padding: 2,
		paddingBottom: 0,
		paddingTop: 1,
	},
	tabs: {
		minHeight: "250px",
	},
	tab: {
		backgroundColor: "primary.main",
		color: "black",
		borderRadius: 1,
		boxShadow:
			"0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
		paddingX: 2,
		paddingY: 1,
		margin: 0.5,
		textTransform: "none",
		"&:hover": {
			backgroundColor: "primary.main",
		},
		"&.Mui-selected": {
			color: "black",
			backgroundColor: "primary.main",
		},
	},
	divider: {
		margin: 1,
	},
	searchField: {
		marginTop: 1,
	},
	eventItem: (selectedEventSlug, itemSlug) => ({
		cursor: "pointer",
		borderRadius: 1,
		border: 3,
		boxSizing: "border-box",
		borderColor:
			selectedEventSlug === itemSlug ? "primary.main" : "grey.300",
		padding: 1,
		backgroundColor:
			selectedEventSlug === itemSlug ? "primary.light" : "transparent",
	}),
	eventName: {
		color: "black",
	},
	eventDate: {
		color: "grey",
	},
	contentBox: {
		flexGrow: 1,
	},
	eventStack: {
		overflow: "auto",
		height: 450,
	},
};

const TabPanelContent = (props) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			{...other}
			sx={styles.tabPanel}>
			{value === index && children}
		</Box>
	);
};

const AdminWrapper = () => {
	const location = useLocation();
	const navigate = useNavigate();
  
	// Parsowanie aktualnego URL, aby sprawdzić, który tab jest aktywny
	const getActiveTabFromUrl = () => {
	  const params = new URLSearchParams(location.search);
	  return parseInt(params.get('tab')) || 1;
	};

	const handleRequest = useHandleRequest();
	const [selectedTab, setSelectedTab] = useState(getActiveTabFromUrl());
	const [events, setEvents] = useState([]);
	const [search, setSearch] = useState("");
	const [selectedEvent, setSelectedEvent] = useState(null);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
		navigate(`?slug=${selectedEvent?.slug}&tab=${newValue}`);
	};

	const getSlugFromURL = () => {
		const queryParams = new URLSearchParams(window.location.search);
		return queryParams.get('slug') || '';
	  };

	useEffect(() => {
		handleRequest(getAllEvents).then(response => {
			setEvents(response[1]);
		})
	}, []);


	useEffect(() => {
		const foundEvent = events.find(event => event.slug === getSlugFromURL());
		if (foundEvent) {
			setSelectedEvent({ slug: foundEvent.slug, name: foundEvent.name });
			console.log("ustaw: " + foundEvent.slug)
		}
	}, [events])

	const handleSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const handleItemClick = (item) => {
		setSelectedEvent({ slug: item.slug, name: item.name });
		navigate(`?slug=${item.slug}&tab=${selectedTab}`);
	};

	const handleLogout = (event) => {
		navigate('/');
	}

	return (
		<>
			<Notifications />
			<AppBar sx={styles.appBar}>
				<Toolbar sx={styles.toolbar}>
					<Typography variant="h6">
						VeloStacja{selectedEvent && ": " + selectedEvent.name}
					</Typography>
					<Button variant="secondary" endIcon={<ExitToApp />} sx={styles.logoutButton} onClick={handleLogout}>
						Wyloguj
					</Button>
				</Toolbar>
			</AppBar>
			<Box sx={{ display: "flex" }}>
				<Paper sx={styles.paper}>
					<Tabs
						sx={styles.tabs}
						orientation="vertical"
						value={selectedTab}
						onChange={handleTabChange}>
						<Tab
							label="+ Dodaj Wydarzenie"
							sx={styles.tab}
						/>
						<Tab label="Ustawienia" />
						<Tab label="Zawodnicy" />
						<Tab label="Mapa" />
						<Tab label="Gps'y techniczne" />
					</Tabs>
					<Stack>
						<Divider sx={styles.divider} />
						<TextField
							sx={styles.searchField}
							fullWidth
							size="small"
							placeholder="Search..."
							value={search}
							onChange={handleSearchChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
						<Typography m={1}>Wybierz zawody:</Typography>
						<Stack sx={styles.eventStack}>
							{events
								.filter((item) =>
									item.name
										.toLowerCase()
										.includes(search.toLowerCase()),
								)
								.map((item) => (
									<Box
										key={item.slug}
										m={1}
										onClick={() => handleItemClick(item)}
										sx={styles.eventItem(
											selectedEvent?.slug,
											item.slug,
										)}>
										<Typography
											variant="body1"
											sx={styles.eventName}>
											{item.name}
										</Typography>
										<Typography
											variant="body2"
											sx={styles.eventDate}>
											{item.dateStart}
										</Typography>
									</Box>
								))}
						</Stack>
					</Stack>
				</Paper>
				<Box sx={styles.contentBox}>
					<TabPanelContent
						value={selectedTab}
						index={0}>
						<AddEventForm setSelectedEvent={setSelectedEvent} />
					</TabPanelContent>
					<TabPanelContent
						value={selectedTab}
						index={4}>
						<GpsTechniczne selectedEvent={selectedEvent} />
					</TabPanelContent>
					{selectedTab !== 0 &&
						selectedTab !== 4 &&
						(selectedEvent ? (
							<>
								<TabPanelContent
									value={selectedTab}
									index={1}>
									<SettingsEventForm
										selectedEvent={selectedEvent}
										events={events}
									/>
								</TabPanelContent>
								<TabPanelContent
									value={selectedTab}
									index={2}>
									<Competitors
										selectedEvent={selectedEvent}
									/>
								</TabPanelContent>
								<TabPanelContent
									value={selectedTab}
									index={3}>
									<MapEditor selectedEvent={selectedEvent} />
								</TabPanelContent>
							</>
						) : (
							<Typography sx={{mt: 2, ml: 1}}>
								Wybierz lub dodaj wydarzenie aby rozpocząć
							</Typography>
						))}
				</Box>
			</Box>
		</>
	);
};

export default AdminWrapper;
