import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import MessagesContextProv from "./inne/MessagesContextProv";
import NotFoundPage from "./inne/NotFoundPage";
import theme from "./inne/theme";
import StartPage from "./stronaGlowna/StartPage";
import AdminWrapper from "./Admin/AdminWrapper";
import EventMap from "./mapa/EventMap";
import Zawody from "./stronaGlowna/Zawody";
import Kontakt from "./stronaGlowna/Kontakt";
import Login from "./Admin/Login";
import { ProtectedRoute } from "./inne/ProtectedRoute";

const routeConfig = [
	{ path: "/", element: <StartPage /> },
	{ path: "/:slug/:trackedPlayerIdParam?", element: <EventMap /> },
	{ path: "/panel/login", element: <Login /> },
	{ path: "wydarzenia", element: <Zawody /> },
	{ path: "kontakt", element: <Kontakt /> },
	{ path: "*", element: <NotFoundPage /> },
];

const App = () => {
	return (
		<MessagesContextProv>
			<ThemeProvider theme={theme}>
				<Router basename={process.env.PUBLIC_URL}>
					<Routes>
						{routeConfig.map((route, index) => (
							<Route
								key={index}
								path={route.path}
								element={route.element}
							/>
						))}
						
						<Route path="admin" element={<ProtectedRoute />}>
							<Route path="" element={<AdminWrapper /> } />
						</Route>
					</Routes>
				</Router>
			</ThemeProvider>
		</MessagesContextProv>
	);
};

export default App;
