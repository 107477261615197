import axios from "axios";
const getListaZawodnikow = async (slug) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/event/${slug}/competitors`,
			{},
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default getListaZawodnikow;
