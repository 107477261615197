import React, { useState, useEffect } from "react";
import {
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
	Box,
	Input,
	Stack,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import useNotifications from "../inne/useNotifications";

const styles = {
	formBox: {
		display: "flex",
		flexDirection: "column",
		gap: 2,
		maxWidth: 900,
	},
};

const AddEventForm = ({ setSelectedEvent }) => {
	const addNotification = useNotifications();
	const [formData, setFormData] = useState({
		eventName: "",
		slug: "",
		eventDescription: "",
		startDateTime: "",
		endDateTime: "",
		isPublic: true,
		eventImage: null,
	});

	useEffect(() => {
		setSelectedEvent(null);
	}, []);

	const handleUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			setFormData((prev) => ({ ...prev, eventImage: file }));
		} else {
			setFormData((prev) => ({ ...prev, eventImage: null }));
		}
	};

	const handleChange = (e) => {
		const { name, value, type, checked, files } = e.target;
		setFormData({
			...formData,
			[name]:
				type === "checkbox"
					? checked
					: type === "file"
					? files[0]
					: value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		addNotification("Dodano Zawody!", "success");
		setSelectedEvent({ slug: formData.slug, name: formData.eventName });
	};

	return (
		<Box
			component="form"
			onSubmit={handleSubmit}
			sx={styles.formBox}>
			<Stack
				direction="row"
				spacing={1}
				width="100%">
				<TextField
					fullWidth
					label="Nazwa wydarzenia"
					name="eventName"
					value={formData.eventName}
					onChange={handleChange}
					required
				/>
				<TextField
					fullWidth
					label="Slug wydarzenia"
					name="slug"
					value={formData.slug}
					onChange={handleChange}
					required
				/>
			</Stack>
			<TextField
				label="Opis wydarzenia"
				name="eventDescription"
				value={formData.eventDescription}
				onChange={handleChange}
				multiline
				rows={4}
			/>
			<Stack
				direction="row"
				spacing={1}
				width="100%">
				<TextField
					fullWidth
					label="Data i godzina rozpoczęcia"
					name="startDateTime"
					type="datetime-local"
					value={formData.startDateTime}
					onChange={handleChange}
					required
					InputLabelProps={{
						shrink: true,
					}}
				/>
				<TextField
					fullWidth
					label="Data i godzina zakończenia"
					name="endDateTime"
					type="datetime-local"
					value={formData.endDateTime}
					onChange={handleChange}
					required
					InputLabelProps={{
						shrink: true,
					}}
				/>
			</Stack>
			<Stack spacing={1}>
				<label htmlFor="eventImage">
					<Input
						id="eventImage"
						type="file"
						name="eventImage"
						accept="image/*"
						style={{ display: "none" }}
						onChange={handleUpload}
					/>
					<Button
						variant="outlined"
						component="span"
						startIcon={<UploadIcon />}>
						Wgraj logo/zdjęcie wydarzenia
					</Button>
					<Box>
						<small>Min. rozmiar grafiki: 300x200</small>
					</Box>
				</label>
				<FormControlLabel
					control={
						<Checkbox
							checked={formData.isPublic}
							onChange={handleChange}
							name="isPublic"
						/>
					}
					label="Dostępne publicznie"
				/>
			</Stack>
			<Button
				type="submit"
				variant="contained"
				color="primary">
				Dodaj
			</Button>
		</Box>
	);
};

export default AddEventForm;
