import React, { createContext, useState } from "react";

const MessagesContext = createContext();

export default function MessagesContextProv({ children }) {
	const [messages, setMessages] = useState([]);

	return (
		<MessagesContext.Provider value={[messages, setMessages]}>
			{children}
		</MessagesContext.Provider>
	);
}

export { MessagesContext };
