import { Stack, Box, Button, Input, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState, useEffect } from "react";
import useHandleRequest from "../requests/useHandleRequest";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import getCompetitorsAdmin from "../requests/getCompetitorsAdmin";
import EditCompetitorModal from "./EditCompetitorModal";
import getEvent from "../requests/getEvent";
import useNotifications from "../inne/useNotifications";
import dataGridLocaleText from "../inne/dataGridLocaleText";
import postCompetitorModify from "../requests/postCompetitorModify";

const styles = {
	container: {
		display: "flex",
		width: "100%",
		justifyContent: "space-between",
		alignItems: "center",
	},
	filterContainer: {
		display: "flex",
		flexGrow: 1,
		width: "100%",
		overflow: "hidden",
		alignItems: "center",
		justifyContent: "center",
	},
	dataGridContainer: {
		height: 700,
		width: "100%",
		overflow: "auto",
	},
	uploadInput: {
		display: "none",
	},
	button: {
		margin: "0 8px",
	},
};

const columns = (handleEdit, onDelete) => {
	return [
		{
			field: "actions",
			type: "actions",
			headerName: "Edytuj / Usuń",
			minWidth: 120,
			cellClassName: "actions",
			getActions: (params) => {
				return [
					<GridActionsCellItem
						icon={<EditIcon />}
						label="Edit"
						className="textPrimary"
						color="inherit"
						onClick={() => {
							handleEdit(params.id);
						}}
					/>,
					<GridActionsCellItem
						icon={<DeleteIcon />}
						label="Delete"
						color="inherit"
						onClick={() => {
							onDelete(params.id);
						}}
					/>,
				];
			},
		},
		{
			field: "name",
			headerName: "Name",
			minWidth: 180,
			type: "text",
		},
		{
			field: "startNumber",
			headerName: "Nr. start",
			minWidth: 90,
			type: "number",
			align: "left",
		},
		{
			field: "groupNumber",
			headerName: "Nr. Grupy",
			minWidth: 100,
			type: "number",
			align: "left",
		},
		{
			field: "female",
			headerName: "Female",
			minWidth: 100,
			type: "boolean",
		},
		{
			field: "teamName",
			headerName: "Team Name",
			minWidth: 150,
			type: "text",
		},
		{
			field: "timeStart",
			headerName: "Start Time",
			minWidth: 150,
			type: "datetime",
		},
		{
			field: "categories",
			headerName: "Categories",
			minWidth: 150,
			type: "text",
		},
		{
			field: "deviceName",
			headerName: "Device Name",
			minWidth: 150,
			type: "text",
		},
		{
			field: "deviceSerialNumber",
			headerName: "Serial Number",
			minWidth: 150,
			type: "number",
		},
		{
			field: "lastUpdate",
			headerName: "Last Update",
			minWidth: 150,
			type: "datetime",
		}
	];
};

export default function Competitors({ selectedEvent }) {
	const handleRequest = useHandleRequest();
	const [rows, setRows] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [currentCompetitor, setCurrentCompetitor] = useState(null);
	const [event, setEvent] = useState({});
	const addNotification = useNotifications();

	useEffect(() => {
		const fetchData = async () => {
			const [eventSuccess, eventResponse] = await handleRequest(
				getEvent,
				selectedEvent.slug,
			);
			if (eventSuccess) setEvent(eventResponse);

			const [success, response] = await handleRequest(
				getCompetitorsAdmin,
				selectedEvent.slug,
			);

			if (success)
				setRows(
					response.map((comp, index) => ({
						id: comp.id,
						...comp,
						deviceName: comp.device?.name,
						serialNumber: comp.device?.serialNumber,
						lastUpdate: comp.device?.lastUpdate,
						visible: true
					})),
				);
		};
		fetchData();
	}, [selectedEvent]);

	const onDelete = (id) => {
		setRows(rows.filter((row) => row.id !== id));
		addNotification(
			`Usunięto uczestnika Nr.: ${
				rows.find((row) => row.id === id).startNumber
			}`,
			"success",
		);
	};

	const filterCompetitors = (query, rows, setRows) => {
		if (query.length > 0) {
			const filtered = rows.map((row) => {
				const joinedRow =
					row.name + row.teamName + row.deviceName;
				const isVisible = joinedRow
					.toUpperCase()
					.replace(/\s/g, "")
					.includes(query.toUpperCase().replace(/\s/g, ""));
				return { ...row, visible: isVisible };
			});
			setRows(filtered);
		} else {
			setRows(rows.map((row) => ({ ...row, visible: true })));
		}
	};

	useEffect(() => {
		if (rows.length > 0) {
			filterCompetitors(searchText, rows, setRows);
		}
	}, [searchText]);

	const handleEdit = (id) => {
		const competitor = rows.find((row) => row.id === id);
		setCurrentCompetitor(competitor);
		setEditModalOpen(true);
	};

	const handleAddPlayer = () => {
		setCurrentCompetitor(null);
		setEditModalOpen(true);
	};

	const handleUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			alert(`handleUpload ${file.name}`);
		}
	};

	const handleSave = (updatedCompetitor) => {
		handleRequest(
			postCompetitorModify, {
				id: updatedCompetitor.id,
				name: updatedCompetitor.name,
				female: updatedCompetitor.female,
				deviceName: updatedCompetitor.device?.name,
				timeStart: updatedCompetitor.timeStart,
				categories: updatedCompetitor.categories,
				teamName: updatedCompetitor.teamName
			})
			.then(result => {
				const [success, response] = result;
				if(success) {
					if (currentCompetitor) {
						setRows(
							rows.map((row) =>
								row.id === currentCompetitor.id ? updatedCompetitor : row,
							),
						);
					} else {
						setRows([...rows, { ...updatedCompetitor, id: response.id }]);
					}
				}
			}
		);

		setEditModalOpen(false);
	};

	return (
		<Stack spacing={2}>
			<EditCompetitorModal
				event={event}
				open={editModalOpen}
				handleClose={() => setEditModalOpen(false)}
				competitor={currentCompetitor}
				onSave={handleSave}
				rowsLength={rows.length}
			/>
			<Box sx={styles.container}>
				<Stack
					direction="row"
					spacing={2}>
					<TextField
						label="Szukaj"
						size="small"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
					/>
					<Button
						variant="contained"
						startIcon={<AddIcon />}
						onClick={handleAddPlayer}
						sx={styles.button}>
						Dodaj Zawodnika
					</Button>
				</Stack>
				<label htmlFor="upload-excel">
					<Input
						id="upload-excel"
						type="file"
						accept=".xlsx, .xls"
						sx={styles.uploadInput}
						onChange={handleUpload}
					/>
					<Button
						variant="outlined"
						component="span"
						startIcon={<UploadIcon />}
						sx={styles.button}>
						Przekaz excel
					</Button>
				</label>
			</Box>
			<Box sx={styles.filterContainer}>
				<Box sx={styles.dataGridContainer}>
					<DataGrid
						sx={{ flexGrow: 1 }}
						rows={rows.filter((row) => row.visible)}
						columns={columns(handleEdit, onDelete)}
						density="compact"
						disableColumnMenu
						disableColumnFilter
						disableColumnSelector
						disableDensitySelector
						localeText={dataGridLocaleText}
						disableMultipleRowSelection
						autoHeight={false}
						disableMultipleColumnsFiltering={false}
						disableRowSelectionOnClick
						autosizeOptions={{
							includeOutliers: false,
							includeHeaders: true,
						}}
					/>
				</Box>
			</Box>
		</Stack>
	);
}
