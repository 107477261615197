import axios from "axios";

const postCompetitorModify = async (data) => {
	try {
		const response = await axios.post(
			`${process.env.REACT_APP_API_URL}/admin/competitor/modify`, data
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default postCompetitorModify;