import axios from "axios";

const deleteMarker = async (markerId) => {
	try {
		const response = await axios.delete(
			`${process.env.REACT_APP_API_URL}/admin/marker/remove/${markerId}`,
			{},
		);
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default deleteMarker;
