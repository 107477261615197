import React, { useContext, useCallback } from "react";
import { Stack, Snackbar, Alert } from "@mui/material";
import { MessagesContext } from "./MessagesContextProv";
import Slide from "@mui/material/Slide";

// Define styles in an object
const styles = {
	stack: {
		direction: "column",
		zIndex: 9999999,
		position: "absolute",
		bottom: "16px",
		left: "16px",
	},
	alert: {
		minWidth: 250,
		position: "fixed",
		left: "16px",
		zIndex: 9999,
	},
};

function Notifications() {
	const [messages, setMessages] = useContext(MessagesContext);

	const handleClose = useCallback(
		(event, reason, id) => {
			if (reason === "clickaway") {
				return;
			}
			setMessages((prev) => prev.filter((msg) => msg.id !== id));
		},
		[setMessages],
	);

	const displayedMessages = messages.slice(0, 3);

	return (
		<Stack style={styles.stack}>
			{displayedMessages.map((msg, index) => (
				<Snackbar
					key={msg.id}
					open={true}
					autoHideDuration={5000}
					onClose={(event, reason) =>
						handleClose(event, reason, msg.id)
					}
					TransitionComponent={Slide}>
					<Alert
						onClose={(event, reason) =>
							handleClose(event, reason, msg.id)
						}
						severity={msg.severity}
						variant="filled"
						style={{
							...styles.alert,
							bottom: `${16 + index * 56}px`,
						}}
						aria-describedby={`alert-${msg.id}`}
						role="alert">
						{msg.label}
						<span
							id={`alert-${msg.id}`}
							style={{
								position: "absolute",
								width: 0,
								height: 0,
								overflow: "hidden",
							}}>
							{msg.label}{" "}
						</span>
					</Alert>
				</Snackbar>
			))}
		</Stack>
	);
}

export default Notifications;
