import React, { useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import LayerSelectionModal from "../views/LayerSelectionModal";
import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";

const styles = { position: "fixed", bottom: "96px", right: 16, zIndex: 999 };

const LayersFab = ({ showLayer, setShowLayer }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const openModal = () => setIsModalOpen(true);
	const closeModal = () => setIsModalOpen(false);

	return (
		<>
			<Tooltip title="Widoczne Warstwy">
				<Fab
					size="large"
					color="primary"
					onClick={openModal}
					sx={styles}>
					<EditLocationAltIcon />
				</Fab>
			</Tooltip>

			<LayerSelectionModal
				showLayer={showLayer}
				setShowLayer={setShowLayer}
				isOpen={isModalOpen}
				onClose={closeModal}
			/>
		</>
	);
};

export default LayersFab;
