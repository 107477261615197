import axios from "axios";
const getAllEvents = async () => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/event`, {});
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default getAllEvents;
