import { useCallback, useContext } from "react";
import { MessagesContext } from "./MessagesContextProv";

function useNotifications() {
	const [messages, setMessages] = useContext(MessagesContext);

	const handleAddNotification = useCallback(
		(label, severity) => {
			const newMsg = {
				label,
				severity,
				id: new Date().getTime(),
			};

			setMessages((prev) => [newMsg, ...prev]);
		}, [messages]);

	return handleAddNotification;
}

export default useNotifications;
