const dataGridLocaleText = {
	noRowsLabel: "Brak Danych",
	columnHeaderSortIconLabel: "Sortuj",
	MuiTablePagination: {
		labelRowsPerPage: "Liczba wierszy na stronę:",
		labelDisplayedRows: ({ from, to, count }) =>
			`${from} - ${to} z ${count === -1 ? `more than ${to}` : count}`,
	},
};

export default dataGridLocaleText;
