import axios from "axios";
const getDevices = async () => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_API_URL}/device`, {});
		return [true, response.data];
	} catch (error) {
		console.error(error);
		return [false, error];
	}
};

export default getDevices;
