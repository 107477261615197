import React from "react";
import { CircularProgress } from "@mui/material";

const style = {
	width: "100%",
	height: "100%",
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
};

function Spinner() {
	return (
		<div style={style}>
			<CircularProgress disableShrink />
		</div>
	);
}

export default Spinner;
