import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Stack, Box } from "@mui/material";
import SiteWrapper from "./SiteWrapper";
import Notifications from "../inne/Notifications";
import useNotifications from "../inne/useNotifications";
import postEmailKontakt from "../requests/postEmailKontakt";

const styles = {
	headerBox: {
		textAlign: "center",
		backgroundColor: "secondary",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		backgroundImage: `url(${process.env.PUBLIC_URL}/images/photo_main.png)`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		height: "350px",
		width: "100%",
	},
	headerTypography: {
		fontWeight: "700",
		fontFamily: "Arial",
		color: "white",
		fontSize: { xs: "35px", md: "75px" },
	},
	headerButton: {
		marginTop: "20px",
		color: "white",
		borderColor: "white",
		"&:hover": {
			borderColor: "white",
			backgroundColor: "rgba(255, 255, 255, 0.1)",
		},
	},
};

const Kontakt = () => {
	const addNotification = useNotifications();
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		postEmailKontakt(formData)
			.then(response => {
				console.log(response[1]);

				setFormData({
					name: "",
					email: "",
					phone: "",
					message: "",
				})
				addNotification(`Wysłano!`, "success");
			})
			.catch(ex => {
				console.error(ex);
				addNotification(`Wystąpił błąd. Spróbuj ponownie`, "error");
			})
	};

	return (
		<SiteWrapper>
			<Notifications />
			<Box sx={styles.headerBox}>
				<Stack
					spacing={1}
					alignItems="center"
					justifyContent="center">
					<Typography
						variant="h2"
						sx={styles.headerTypography}>
						Kontakt
					</Typography>
				</Stack>
			</Box>
			<Box py={4}>
				<Typography
					variant="h4"
					component="h2"
					align="center"
					gutterBottom>
					Szukasz partnera do organizacji wydarzenia?
				</Typography>
				<Typography
					variant="body2"
					align="center"
					paragraph>
					Skontaktuj się z nami, a my przedstawimy Ci nasze
					kompleksowe usługi.
				</Typography>
				<form onSubmit={handleSubmit}>
					<Grid
						container
						padding={1}
						spacing={2}>
						<Grid
							item
							xs={12}>
							<TextField
								fullWidth
								label="Imię i Nazwisko"
								variant="outlined"
								placeholder="Jan Kowalski"
								name="name"
								value={formData.name}
								onChange={handleChange}
								required
							/>
						</Grid>

						<Grid
							item
							xs={12}
							md={6}>
							<TextField
								fullWidth
								label="Email"
								variant="outlined"
								placeholder="email@domain.com"
								type="email"
								name="email"
								value={formData.email}
								onChange={handleChange}
								required
							/>
						</Grid>

						<Grid
							item
							xs={12}
							md={6}>
							<TextField
								fullWidth
								label="Numer Telefonu"
								variant="outlined"
								placeholder="XXX XXX XXX"
								type="tel"
								name="phone"
								value={formData.phone}
								onChange={handleChange}
								required
							/>
						</Grid>

						<Grid
							item
							xs={12}>
							<TextField
								fullWidth
								label="Wiadomość"
								variant="outlined"
								placeholder="Data wydarzenia, przewidywana liczba uczestników/wyposażenie wymagane, rodzaj wydarzenia…"
								multiline
								rows={4}
								name="message"
								value={formData.message}
								onChange={handleChange}
							/>
						</Grid>

						<Grid
							item
							xs={12}>
							<Box textAlign="center">
								<Button
									variant="contained"
									color="primary"
									type="submit">
									Wyślij
								</Button>
							</Box>
						</Grid>
					</Grid>
				</form>
			</Box>
		</SiteWrapper>
	);
};

export default Kontakt;
