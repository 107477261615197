import useNotifications from "../inne/useNotifications";

const useHandleRequest = () => {
	const addNotification = useNotifications();

	const handleRequest = async (request, otherData) => {
		const [success, response] = await request(otherData);
		if (!success) {
			console.error(response);
			addNotification(`Błąd: ${response.message}`, "error");
		}

		return [success, response];
	};

	return handleRequest;
};

export default useHandleRequest;
