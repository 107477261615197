import { useState, useEffect } from "react";
import { Autocomplete, TextField } from '@mui/material';
import useHandleRequest from "../../requests/useHandleRequest";
import getDevices from "../../requests/getDevices";

const SelectDeviceComponent = ({ onDeviceSelect, deviceName }) => {
    const handleRequest = useHandleRequest();
    const [loading, setLoading] = useState(true);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        handleRequest(getDevices)
        .then(response => {
            console.log(response[1]);
            setDevices(response[1]);
            setLoading(false);
        })
        .catch(error => {
            console.error("Błąd podczas pobierania urządzeń:", error);
        });
    }, []);

    useEffect(() => {
        if (deviceName && devices.length > 0) {
            const foundDevice = devices.find(device => device.name === deviceName);
            if (foundDevice) {
                setSelectedDevice(foundDevice);
                if (onDeviceSelect) {
                onDeviceSelect(foundDevice); // Informuj rodzica o początkowym wyborze
                }
            }
        }
    }, [deviceName, devices]); // Uruchom, gdy deviceName lub devices się zmieni

    const handleDeviceChange = (event, newValue) => {
        setSelectedDevice(newValue);
        if (onDeviceSelect) {
            onDeviceSelect(newValue); // Zwracanie wybranego urządzenia do rodzica
        }
    };

    if(loading)
        return <>Ładowanie urządzeń...</>

    return (
      <Autocomplete
        options={devices}
        getOptionLabel={(device) => device.name}
        value={selectedDevice}
        onChange={handleDeviceChange}
        renderInput={(params) => (
          <TextField {...params} label="Wybierz urządzenie" variant="outlined" />
        )}
      />
    );
  };

  export default SelectDeviceComponent;
  